/*
|-------------------------------------------------------------------------------
| VUEX modules/categories.js
|-------------------------------------------------------------------------------
| The Vuex data store for the categories
*/

export const categories = {
  state: {
    requestedCategories: {}
  },
  getters: {
    activeCategories: state => {
      return state.requestedCategories;
    }
  },
  actions: {
    updateCategories({commit}, item) {
      commit('setCategories', item);
    }
  },
  mutations: {
    setCategories(state, item) {
      state.requestedCategories = item;
    }
  }
}

