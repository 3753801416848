export function encodeQueryData(data) {
    const params = [];
    if (Object.keys(data).length === 0 && data.constructor === Object) {
        return "";
    }
    
    for (let d in data)
        params.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return "?" + params.join('&');
}

export function buildUrl(endpoint, encodedParams = ""){
    return endpoint + encodedParams;
}

export function getEnvironmentVar(varname, defaultvalue) {
    var result = process.env[varname];
    if (result!=undefined) {
        return result;
    } else {
        return defaultvalue;
    }
}

