<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="modal-wrapper modal-lg"
      @keyup.tab.prevent="trapFocus"
      v-click-outside="clickAwayClose">
        <div class="modal-container eventMessage-container modal-lg" v-if="transactionFeedback.message != ''">
          <div class="eventMessage-header">
            <div class="modal__close">
              <button type="button" class="modal__close" @click="clickAwayClose">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <success-error-message
            :httpCode="transactionFeedback.code"
            :httpMessage="transactionFeedback.message"
            @close="clickAwayClose">
          </success-error-message>
        </div>
        <div class="modal-container modal-lg" v-if="transactionFeedback.message == ''">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="modal__close" @click="clickAwayClose">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="modal-body" v-if="selectData">
            <div class="modal-body-toedge flex-row">
              <div class="flex-column">
                <div class="fieldGroupSolo">
                    <label for="fullName">Full Name</label>
                    <input id="fullName" name="fullName" :value="fullName" class="form-control" type="text" disabled>
                </div>
                <simple-input
                    :inputs="inputs"
                    :sentData="copiedData"
                    :checkValidity="validateInputs"
                    @valid="passFailInputs">
                </simple-input>
              </div>
              <div class="flex-column-wide">
                <file-drop
                  :acceptedFormats="'.pdf'"
                  @selectedFiles="setFiles">
                    <data-grid-container slot="existingFileGrid" v-if="sentAction != 'post'"
                          :columns="gridColumns"
                          :endPoint="fileGrid.url"
                          :sentGridData="fileData"
                          :filterKey="searchQuery"
                          :gridName="'common-grid'"
                          :searchable="false"
                          :printable="false"
                          :activeInactive="false"
                          :editableTable="false"
                          @reloadPageInfo="getFilesInfo">
                    </data-grid-container>
                </file-drop>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div v-if="sentAction != 'post'" class="additionalButtons flex-left">
                <button class="btn btn-outline-default" @click="addNewRecord">
                  Add New
                </button>
                <button class="btn btn-danger" @click="deleteRecord">
                  Delete
                </button>
              </div>
              <div class="defaultButtons">
                <button class="btn btn-success" type="submit" form="form1" value="Submit" @click.prevent="checkInputs()">Submit</button>
                <button class="btn btn-outline-default" @click="clickAwayClose">
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { DataGridContainer } from "@/components";
import FileDrop from './FileDrop.vue';
import SimpleInput from "./Modals/SimpleInput.vue";
import modalMixins from '../utilities/modalMixins';
import inputValidationParent from "../utilities/inputValidationParent";
import orgMixins from "../utilities/orgMixins"
import SuccessErrorMessage from './SuccessErrorMessage.vue';
import generalServices from '../services/GeneralServices';
import FileServices from '../services/FileServices';

export default {
  name: 'complex-modal',
  props: {
    action: String,
    inputs: Array,
    sentData: {
      type: Object,
      default () {
        return {}
      }
    },
    endPoint: String,
    postId: String,
  },
  mixins: [modalMixins, orgMixins, inputValidationParent],
  components: {
    DataGridContainer,
    FileDrop,
    'success-error-message': SuccessErrorMessage,
    'simple-input': SimpleInput,
  },
  data: function() {
    return {
        searchQuery: "",
        infoProvided: "",
        counter: 0,
        copiedData: {},
        employeeData: {},
        selectCategories: [],
        selectData: [],
        sentAction: "",
        transactionFeedback: {
            'message': "",
            'code': ""
        },
        putId: "",
        files:[],
        fileData: [],
        gridColumns: [
            {name: "checkbox", type: "checkbox", editable: false},
            {name: "_id", type: "select", editable: false, openFile: true}
        ],
        fileGrid: {
            url: "",
            getUrl: "",
            fileKey: "training"
        },
    };
  },
  mounted() {
    this.sentAction = this.action;
    this.loadData();
    this.getEmployeeInfo();
    this.getCategories();

    if (this.sentAction != "post") {
        this.getFilesInfo();
    }
  },
  filters: {

  },
  computed: {

  },
  methods: {
    getCategories() {
      this.selectData = this.$store.getters.activeCategories;
    },
    setFiles (fileArray) {
      this.files = fileArray;
    },
    async getEmployeeInfo(){
      try {
          let employeeInfo = await generalServices.GET_DATA("employees/" + this.postId + "/", this.queryParams);
          
          this.employeeData = employeeInfo.data[0];
      } catch (err) {
        this.employeeGridData = [];
        console.log(err);
      }
    },
    async getFilesInfo (value){
      try {
        let fileInfo = await FileServices.GET_FILES("files/" + this.putId + "/", 'training', this.queryParams);

        this.fileData = fileInfo.data;
      } catch (err) {
        this.fileData = [];
        console.log(err);
      }

      if (value) {
        const filteredFiles = this.formInfo['files'].filter((item) => item.$oid !== value);
        this.formInfo['files'] = filteredFiles;
      }
    },
    async postModal (formInfo){        
      if (this.sentAction == "post") {
        try {
          let response = await FileServices.POST_FILES(this.endPoint + this.postId + "/", this.cleanFormData(formInfo, this.files), this.queryParams);

          this.transactionFeedback['message'] = response.data;
          this.transactionFeedback['code'] = response.status;
          this.$emit('updateGrid');
        } catch (err) {
          this.transactionFeedback['message'] = err.response.data;
          this.transactionFeedback['code'] = err.response.status;
        }
      } else {
        let fileData = new FormData();

        //SEND TO THE PUT ENDPOINT
        try {
            for ( var key in this.copiedData ) {
                if (key in formInfo) {
                    this.copiedData[key] = formInfo[key];
                }
            }
            let response = await generalServices.PUT_DATA(this.endPoint, this.putId, this.copiedData, this.queryParams);

            this.transactionFeedback['message'] = response.data;
            this.transactionFeedback['code'] = response.status;
            this.$emit('updateGrid');
        } catch (err) {
          this.transactionFeedback['message'] = err.response.data;
          this.transactionFeedback['code'] = err.response.status;
        }

        if (this.files.length != 0) {
          for ( var i in this.files ) {
            fileData.append('file', this.files[i]);
          }
          try {
            let response2 = await FileServices.POST_FILES(this.endPoint + this.putId + "/", fileData, this.queryParams);

            //this.transactionFeedback['message'] = response2.data;
            //this.transactionFeedback['code'] = response2.status;
            this.$emit('updateGrid');
          } catch (err) {
            //this.transactionFeedback['message'] = err.response.data;
            //this.transactionFeedback['code'] = err.response.status;
          }
        }
      }
    },
    addNewRecord() {
      let toCopy = ["trainingCourse"];
      let forSent = {};
      for ( var key in this.inputs ) {
        if (toCopy.includes(this.inputs[key]['category'])) {
          forSent[this.inputs[key]['category']] = this.copiedData[this.inputs[key]['category']];
        } else if (this.inputs[key]['category'] != "status"){
            forSent[this.inputs[key]['category']] = "";
        } else {
            forSent['status'] = "Active";
        }
      }

      this.copiedData = forSent;
      this.fileData = [];
      this.sentAction = 'post';
    },
    async deleteRecord() {
      if(confirm("Do you really want to delete?")){
        try {
          let response = await generalServices.DELETE_DATA(this.endPoint, this.putId, this.queryParams);

          this.transactionFeedback['message'] = response.data;
          this.transactionFeedback['code'] = response.status;
          this.$emit('updateGrid');
        } catch (err) {
          this.transactionFeedback['message'] = err.response.data;
          this.transactionFeedback['code'] = err.response.status;
        }
      }
    },
    loadData(){
        if (this.sentData) {
            this.copiedData = JSON.parse(JSON.stringify(this.sentData));
            if (this.sentAction != "post") {
                this.putId = this.sentData['_id']['$oid'];
                this.fileGrid.url = "training/" + this.putId + "/";
            }
        }
    },
    isEmptyObject(obj){
        return Object.keys(obj).length === 0;
    },
  },
  watch: {

  }
};
</script>
<style lang="scss">

</style>
