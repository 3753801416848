<template>
    <div>
        <section>
            <div class="flex-row section-container flex-justify-center">
                <div class="flex-column">
                    <h1>HSE HUB - All features in one spot.</h1>
                    <div class="flex-row flex-justify-center btn-container">
                        <!--<router-link class="btn btn-outline-default" as="button" :to="{ name: 'Features'}">Employee Lists</router-link>-->
                        <router-link class="btn btn-outline-default" as="button" :to="{ name: 'TrainingManagement'}">Training Management</router-link>
                    </div>
                </div>
            </div>
        </section>
        <transition name="fade" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
        </transition>
        <section class="flex-row light-gray">
            <div class="flex-column flex-1 flex-item-center section-container search">
                <h2>Want to see more?</h2>
                <button class="btn btn-success" @click="requestDemo = true; openModal('showModal')">Request demo</button>
            </div>
        </section>
        <contact-modal v-if="showModal"
                    :inputs="modalInputs"
                    :demo="true"
                    @close="requestDemo = false; closeModal('showModal')">
            <h2 slot="header">Contact Us</h2>
        </contact-modal>
    </div>
</template>
<script>
import { SvgIconContainer } from "@/components";
import { ContactUsModal } from "@/components";
import modalEventMixins from '../../utilities/modalEventMixins';

export default {
  components: {
    'svg-icon-container': SvgIconContainer,
    'contact-modal': ContactUsModal
  },
  mixins: [modalEventMixins],
  data() {
    return {
      activeFeature: "training",
      showModal: false,
      requestDemo: false,
      modalInputs: [
        {type: "email", category: "fromEmail", required: true, alternateLabel: "Your Email"},
        {type: "text", category: "subject", required: true},
        {type: "textarea", category: "message", required: true},
        {type: "textarea", category: "userComments"},
      ],
      cards: [
        {
            'svg': 'clientDrivenIcon',
            'title': 'Client and expert driven',
            'description': "Our platform is deeply rooted in the industry as its development takes into account valuable feedback from a community of health, safety and environmental (HSE) practitioners / professionals, our clients and individual users."
        },
        {
            'svg': 'connectionIcon',
            'title': 'Connection of features and data',
            'description': "We help you reduce the need for excessive administrative resources and potentially quality issues by connecting our features and data together, as well as through our various sharing options."
        },
        {
            'svg': 'keepItSimpleIcon',
            'title': 'Keeping it simple',
            'description': "Don't like over complication? Neither do we. We aim to make our platform easy to learn, use and to navigate with the fewest clicks possible."
        },
        {
            'svg': 'removeBarrierIcon',
            'title': 'Removing barriers through accessibility',
            'description': "We work with you to understand and remove potential barriers that may prevent you from accessing or using our platform."
        },
      ],
    };
  },
  created () {
    
  },
  mounted() {
    
  },
  filters: {
    
  },
  computed: {

  },
  methods: {
    
  }
};</script>
<style lang="scss">
</style>
