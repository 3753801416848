<template>
  <div>
    <input type="checkbox" name="tableId" 
      :id="inputID" 
      :value='checkValue'
      :aria-label="labelText"
      @change="$emit('input', $event.target.value)">
    <label class="sreader-only" :for="inputID">{{ labelText }}</label>
  </div>
</template>
<script>
export default {
  name: 'custom-checkbox',
  props: {
    checkValue: String,
    inputID: String,
    labelText: {
      type: String,
      required: true,
    },
    modelItem: "",
  },
  methods: {
    updateChecked (e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>
