<template>
  <print-dropdown-button
    :buttonLabel="buttonLabel"
    @printClicked="createTemplate">
  </print-dropdown-button>
</template>
<script>
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';
import PrintDropdownButton from '../PrintDropdownButton.vue';

export default {
  name: 'print-tablesWithImages',
  components: {
    PrintDropdownButton
  },
  props: {
    buttonLabel: String,
    sentGridData: "",
    desiredIds: Array,
    headers: Array,
    title: String,
    hubUUID: String,
  },
  mixins: [printMixins, printFileMixins],
  data() {
    return {
      showMenu: false,
    };
  },
  mounted() {

  },
  methods: {
    async createTemplate () {
      let template = [];
      let filteredArray = this.filterArray (this.sentGridData, this.desiredIds);
      console.log(this.sentGridData);
      let ids = this.cleanPublicFileInfo(filteredArray);
      await this.renderPDF(ids);

      template.push(this.buildPageHeader(this.title));
      template.push(
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            body: this.makeTable (this.headers, this.sentGridData, this.desiredIds)
          }
        }
      );

      template.push(this.resultPdf);
      this.resultPdf = [];
      this.docDefinition['footer'] = (currentPage, pageCount) => {    
        return this.buildPageFooter(currentPage, pageCount, this.hubUUID);
      };
      this.docDefinition['content'] = [template];
      this.pdfMake.createPdf(this.docDefinition).download(this.hubUUID + " - Training Summary and Records.pdf");
    },
  }
};
</script>