/*
|-------------------------------------------------------------------------------
| Mixins for general methods applied accross multiple components/pages.
|-------------------------------------------------------------------------------
*/
export default {
    methods: {
      cleanDates(obj){
        var result = obj;
        let key = "$date";
        
        for (let i1 in obj) {
          if (typeof obj[i1] === "object") {
            for (let i2 in obj[i1]){
              if (i2 == key) {
                let newDate = new Date(obj[i1][i2]).toISOString().substr(0,10);
                result[i1] = newDate;
              } else {
                if (typeof obj[i1][i2] === "object") {
                  for (let i3 in obj[i1][i2]) {
                    if (i3 == key) {
                      if (obj[i1][i2][i3]) {
                        let newDate = new Date(obj[i1][i2][i3]).toISOString().substr(0,10);
                        result[i1][i2] = newDate;
                      }
                    } else {
                      if (typeof obj[i1][i2][i3] === "object") {
                        for (let i4 in obj[i1][i2][i3]) {
                          if (i4 == key) {
                            if (obj[i1][i2][i3][i4]) {
                              let newDate = new Date(obj[i1][i2][i3][i4]).toISOString().substr(0,10);
                              result[i1][i2][i3] = newDate;
                            }
                          } else {
                            if (typeof obj[i1][i2][i3][i4] === "object") {
                              for (let i5 in obj[i1][i2][i3][i4]) {
                                if (i5 == key) {
                                  if (obj[i1][i2][i3][i4][i5]) {
                                    let newDate = new Date(obj[i1][i2][i3][i4][i5]).toISOString().substr(0,10);
                                    result[i1][i2][i3][i4] = newDate;
                                  }
                                } else {
                                  if (typeof obj[i1][i2][i3][i4][i5] === "object") {
                                    for (let i6 in obj[i1][i2][i3][i4][i5]) {
                                      if (i6 == key) {
                                        if (obj[i1][i2][i3][i4][i5][i6]) {
                                          let newDate = new Date(obj[i1][i2][i3][i4][i5][i6]).toISOString().substr(0,10);
                                          result[i1][i2][i3][i4][i5] = newDate;
                                        }
                                      } else {
                                        if (typeof obj[i1][i2][i3][i4][i5][i6] === "object") {
                                          for (let i7 in obj[i1][i2][i3][i4][i5][i6]) {
                                            if (i7 == key) {
                                              if (obj[i1][i2][i3][i4][i5][i6][i7]) {
                                                let newDate = new Date(obj[i1][i2][i3][i4][i5][i6][i7]).toISOString().substr(0,10);
                                                result[i1][i2][i3][i4][i5][i6] = newDate;
                                              }
                                            } else {

                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        return result;
      },
      isOption(toCheck) {
        let options = ["checkbox", "files"]
        if (options.includes(toCheck)){
          return true;
        } else {
          return false;
        }
      },
    }
};
