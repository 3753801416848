<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="modal-wrapper modal-lg"
      @keyup.tab.prevent="trapFocus"
      v-click-outside="clickAwayClose">
        <div class="modal-container eventMessage-container modal-lg" v-if="transactionFeedback.message != ''">
          <div class="eventMessage-header">
            <div class="modal__close">
              <button type="button" class="modal__close" @click="clickAwayClose">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <success-error-message
            :httpCode="transactionFeedback.code"
            :httpMessage="transactionFeedback.message"
            @close="clickAwayClose">
          </success-error-message>
        </div>
        <div class="modal-container modal-lg" v-if="transactionFeedback.message == ''">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="modal__close" @click="clickAwayClose">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="modal-body" v-if="selectData">
            <div class="modal-body-toedge flex-row">
              <div class="flex-column">
                <simple-input
                    :inputs="inputs"
                    :checkValidity="validateInputs"
                    @valid="passFailInputs">
                </simple-input>
              </div>
              <div class="flex-column-wide">
                <file-drop
                  :acceptedFormats="'.pdf'"
                  @selectedFiles="setFiles">
                    <data-grid-container slot="existingFileGrid" v-if="sentAction != 'post'"
                          :columns="gridColumns"
                          :endPoint="fileGrid.url"
                          :sentGridData="fileData"
                          :filterKey="searchQuery"
                          :gridName="'common-grid'"
                          :checkBoxKey="'_id'"
                          :searchable="false"
                          :activeInactive="false"
                          :editableTable="false"
                          :printable="false"
                          :tableName="'files'">
                    </data-grid-container>
                </file-drop>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="defaultButtons">
                <button class="btn btn-success" type="submit" form="form1" value="Submit" @click.prevent="checkInputs()">Submit</button>
                <button class="btn btn-outline-default" @click="clickAwayClose">
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { DataGridContainer } from "@/components";
import FileDrop from './FileDrop.vue';
import SimpleInput from "./Modals/SimpleInput.vue";
import modalMixins from '../utilities/modalMixins';
import inputValidationParent from "../utilities/inputValidationParent";
import SuccessErrorMessage from './SuccessErrorMessage.vue';
import FileServices from '../services/FileServices';

export default {
  name: 'complex-modal-user',
  props: {
    inputs: Array,
    sentData: Object,
    endPoint: String,
    postId: String,
    checkValidity: Boolean,
  },
  mixins: [modalMixins, inputValidationParent],
  components: {
    DataGridContainer,
    FileDrop,
    'success-error-message': SuccessErrorMessage,
    'simple-input': SimpleInput
  },
  data: function() {
    return {
      searchQuery: "",
      copiedData: {},
      employeeData: {},
      selectCategories: [],
      selectData: [],
      sentAction: "",
      transactionFeedback: {
        'message': "",
        'code': ""
      },
      putId: "",
      files:[],
      fileData: [],
      gridColumns: [
        {name: "checkbox", type: "checkbox", editable: false},
        {name: "_id", type: "select", editable: false, openFile: true}
      ],
      fileGrid: {
        url: "",
        getUrl: "",
        fileKey: "training"
      },
    };
  },
  mounted() {
    this.sentAction = this.action;
    this.loadData();
  },
  filters: {

  },
  computed: {
    
  },
  methods: {
    setFiles (fileArray) {
      this.files = fileArray;
    },
    async postModal (formInfo){
        try {
            let response = await FileServices.POST_FILES(this.endPoint + this.postId + "/", this.cleanFormData(formInfo, this.files));

            this.transactionFeedback['message'] = response.data;
            this.transactionFeedback['code'] = response.status;
            this.$emit('updateGrid');
        } catch (err) {
            this.transactionFeedback['message'] = err.response.data;
            this.transactionFeedback['code'] = err.response.status;
        }
    },
    loadData(){
      if (this.sentData) {
        this.copiedData = JSON.parse(JSON.stringify(this.sentData));
        if (this.sentAction != "post") {
          this.putId = this.formInfo['_id']['$oid'];
          this.fileGrid.url = "training/" + this.putId + "/";
        }
      }
    },
  },
  watch: {

  }
};
</script>
<style lang="scss">

</style>
