<template>
  <tbody>
    <tr v-if="filteredData.length < 1">
      <td :colspan="columns.length">
        No information found.
      </td>
    </tr>
    <tr v-else v-for="(entry, index) in filteredData">
      <td tabindex="0" v-if="!isOption(key.name)" v-for="key in columns">
        <div v-if="!key.editable" class="lockedCell">
          <div v-if="key.urlName">
            <router-link :to='{name: key.urlName, params: {id: entry["userId"]["$oid"]}}'>{{entry[key.name]}}</router-link>
          </div>
          <div v-else-if="key.openFile">
            <a class="" @click="openFile(entry['file']['$binary'])">{{entry['name']}}</a>
          </div>
          <div v-else>
            <template v-if="key.name === 'category'">
              {{entry[key.name] | capitalize}}
            </template>
            <template v-else>
              {{entry[key.name]}}
            </template>
          </div>
        </div>
        <div v-else class="editableCell">
          <div v-show="!editCells">
            <div v-if='key.name === "confirmed"'>
              <template v-if="entry[key.name] === true">
                <svg-icon-container
                  class="hi-checkmark" 
                  :hidden="false"
                  :icon="'checkmarkIcon'"
                  :title="'Category confirmed by user'">
                </svg-icon-container>
              </template>
              <template v-else-if="entry[key.name] === false && entry['handledBy'] === 'ai'">
                <svg-icon-container 
                  :hidden="false"
                  :icon="'aiIcon'"
                  :title="'Categorized by AI but not confirmed by user'">
                </svg-icon-container>
              </template>
            </div>
            <div v-else>
              <template v-if="key.name === 'category'">
                {{entry[key.name] | capitalize}}
              </template>
              <template v-else-if='key.type === "date"'>
                {{entry[key.name] | filterDateFront}}
              </template>
              <template v-else>
                {{entry[key.name]}}
              </template>
            </div>
          </div>
          <input v-if='key.type === "text"' v-show="editCells" class="editingCell form-control" type="text"
                :aria-labelledby="key.name"
                v-model="entry[key.name]">
          <select v-if='key.type === "select"' v-show="editCells" class="form-control" 
                    :id="key.category" 
                    :name="key.category" 
                    :aria-labelledby="key.name"
                    v-model="entry[key.name]">
                <template v-if="key.name === 'status'">
                        <option value="Active" :selected="entry[key.name] == 'Active'">
                            Active
                        </option>
                        <option value="Inactive" :selected="entry[key.name] == 'Inactive'">
                            Inactive
                        </option>
                </template>
                <template v-if="key.name === 'confirmed'">
                        <option :value="true" :selected="entry[key.name] == true">
                            Confirm
                        </option>
                        <option :value="false" :selected="entry[key.name] == false">
                            Unconfirm
                        </option>
                </template>
                <template v-if="key.name === 'category'" v-for="category in categories">
                  <option :value="category" :selected="entry[key.category] == category">
                      {{category | capitalize}}
                  </option>
                </template>
                <template v-if="key.name === 'trainingCourse'">
                    <option v-if="selectData[key.name]" v-for="item in selectData[key.name]" :selected="item == entry[key.name]">
                        {{item.name}}
                    </option>
                </template>
                <template v-else>
                    <option v-if="selectData[key.name]" v-for="item in selectData[key.name]" :selected="item == entry[key.name]">
                        {{item}}
                    </option>
                </template>
          </select>
          <input v-if='key.type === "date"' 
                v-show="editCells" type="date"
                class="form-control"
                :aria-labelledby="key.name"
                v-model="entry[key.name]">
        </div>
      </td>
      <td v-else-if="isOption(key.name)">
        <div v-if="key.name == 'checkbox'">
            <input type="checkbox" name="tableId" :id="tableName + index" :value='passedCheckboxKey ? entry[passedCheckboxKey] : entry["_id"]["$oid"]'
                v-model="checkedValues"
                :aria-label="'Select information in row ' + index"
                @change="checkSingle($event)">
            <label class="sreader-only" :for="tableName + index">Select information in row {{ index }}</label>
        </div>
        <button v-if="key.name == 'files' && key.type == 'files'" type="button" class="btn btn-outline-default" 
                :aria-label="'Open files for ' + entry[ariaInformation.specificRecordKey] + ' ' + ariaInformation.tableCollection" 
                @click="showFiles(entry['_id']['$oid'])">
          <svg-icon-container
            focusable="false" 
            :icon="'folderIcon'">
          </svg-icon-container>
        </button>
        <button v-if="filteredData[index]['fileInfo'].length > 0 && key.type == 'viewFiles'" type="button" class="btn btn-outline-default" 
                :aria-label="'Open files for ' + filteredData[index]['trainingCourse']" 
                @click="viewFiles(filteredData[index])">
          <svg-icon-container
            focusable="false" 
            :icon="'viewIcon'"
            class="btn-svg">
          </svg-icon-container>
        </button>         
      </td>
    </tr>
  </tbody>
</template>
<script>
import generalUtilMixins from '../../utilities/generalUtilMixins';
import gridMixins from '../../utilities/gridMixins';
import SvgIconContainer from "../Svg/SvgIconContainer.vue";
import CustomCheckbox from "../CustomCheckbox.vue";
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';

export default {
  name: 'grid-body',
  mixins: [generalUtilMixins, gridMixins, printMixins, printFileMixins],
  components: {
    CustomCheckbox,
    SvgIconContainer,
  },
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
    statusKey: String,
    keyOrder: Object,
    checkBoxKey: String,
    enableEdit: Boolean,
    selectedAll: Boolean,
    sentGridData: "",
    ariaInformation: Object,
    tableName: {
      type: String,
      default: "check"
    },
  },
  data: function() {
    var sortOrders = {};
    this.columns.forEach(function(key) {
      sortOrders[key.name] = 1;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders,
      tableData: [],
      copiedData: [],
      selectData: [],
      receivedData: [],
      checkedValues: [],
      editedValues: {},
      selectAll: false,
      editCells: null,
      passedCheckboxKey: this.checkBoxKey,
      options: ["checkbox", "files"],
      categories: [
          "department",
          "jobTitle",
          "project",
          "provinceState",
          "trainingCourse",
      ]
    };
  },
  mounted() {  
    this.receivedData = this.$store.getters.activeCategories;
  },
  computed: {
    
  },
  filters: {
 
  },
  methods: {
    loadData() {
      this.tableData = this.cleanDates(this.sentGridData);
      this.copiedData = JSON.parse(JSON.stringify(this.tableData));
    },
    checkSingle: function(e, param) {
      if (this.checkedValues.length){
        this.$emit('showOptions', true);
        this.$emit('checkedValues', this.checkedValues);
      } else {
        this.$emit('showOptions', false);
      }
    },
    selectAllBoxes() {
        this.checkedValues = [];
        if (this.selectAll) {
            for (let i in this.filteredData) {
                if (this.passedCheckboxKey) {
                  this.checkedValues.push(this.filteredData[i][this.passedCheckboxKey]);
                } else {
                  this.checkedValues.push(this.filteredData[i]['_id']['$oid']);
                }
            }
            this.$emit('showOptions', true);
            this.$emit('checkedValues', this.checkedValues);
        } else {
            this.$emit('showOptions', false);
        }
    },
    openFile (binaryFile) {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(binaryFile) + "'></iframe>"
      )
    },
    async viewFiles (fileInfo) {
        let tempIdArray = [];
        for (let e in fileInfo['fileInfo']) {
            tempIdArray.push(fileInfo['fileInfo'][e]);
        }

        await this.renderPDF(tempIdArray);
        this.docDefinition['content'] = [this.resultPdf]
        this.resultPdf = [];
        this.pdfMake.createPdf(this.docDefinition).open();
    },
    showFiles (IdForFiles){
      this.$emit('showFiles', IdForFiles);
    },
    getCategories () {
      this.selectData = this.$store.getters.activeCategories;
    },
  },
  watch: {
    enableEdit(newValue) {
      this.editCells = newValue;
      this.getCategories();
    },
    selectedAll(newValue) {
      this.selectAll = newValue;
      this.selectAllBoxes();
    },
    sentGridData() {
      this.loadData();
    },
    keyOrder: {
      handler(value) {
        this.sortBy(value);
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>
<style>
</style>
