import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/*
    Imports all of the modules used in the application to build the data store.
*/
import { auth } from './modules/auth.module.js'
import { categories } from './modules/categories.js'
import { org } from './modules/org.module.js'

/*
  Exports our data store.
*/
export default new Vuex.Store({
  modules: {
    auth,
    categories,
    org
  }
});

