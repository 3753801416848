/*
|-------------------------------------------------------------------------------
| Services for setting up axios general interceptors.
|-------------------------------------------------------------------------------
*/
import axiosInstance from "./axiosInstance";
import { authToken, getRefreshToken } from '../AuthHeader';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = authToken();
      if (token) {
        config.headers["Authorization"] = 'Token ' + token;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry && err.response.data.code == "token_not_valid") {
            originalConfig._retry = true;
            
            return store.dispatch('auth/refreshToken', getRefreshToken()).then(
                (token) => {
                    axiosInstance.defaults.headers.common["Authorization"] = token.access;
                    return axiosInstance.request(originalConfig);
                },
                error => {
                    return Promise.reject(error);
                }
            );

        } else if (err.response.status === 401 && originalConfig._retry) {
            store.dispatch('auth/logout');
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
