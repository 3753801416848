<template>
  <print-dropdown-button
    :buttonLabel="buttonLabel"
    @printClicked="createTemplate">
  </print-dropdown-button>
</template>
<script>
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';
import PrintDropdownButton from '../PrintDropdownButton.vue';

export default {
  name: 'print-trainingSummariesWithImages',
  components: {
    PrintDropdownButton
  },
  props: {
    buttonLabel: String,
    sentGridData: "",
    desiredIds: Array,
    headers: Array,
    title: String,
  },
  mixins: [printMixins, printFileMixins],
  data() {
    return {
      showMenu: false,
      courses: [],
    };
  },
  mounted() {
    this.courses = this.$store.getters.activeCategories['trainingCourse'];
  },
  methods: {
    async createTemplate () {
      let filteredObject = this.filterObject(this.sentGridData);
      let template = [];
      for (let i in filteredObject) {
        template.push(
          {
            stack: [await this.makeSummaries(filteredObject[i], true)],
            id: "oneSummary"
          }
        );
      }

      this.resultPdf = [];
      this.docDefinition['content'] = [template];
      this.pdfMake.createPdf(this.docDefinition).download("optionalName.pdf");
    },
  }
};
</script>