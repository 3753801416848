import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import FeatureLayout from "@/layout/Features/FeatureLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Home from "@/pages/Home.vue";
import Legal from "@/pages/Legal.vue";
import TrainingProfile from "@/pages/TrainingProfile.vue";
import TrainingManagement from "@/pages/TrainingManagement.vue";

const routes = [
    {
        path: "/",
        component: DashboardLayout,
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
            },
            {
                path: "/privacy/",
                name: "Privacy",
                component: Legal,
            },
            {
                path: "/termsofservice/",
                name: "TermsOfService",
                component: Legal,
            },
            {
                path: "/public/training/",
                name: "TrainingProfile",
                component: TrainingProfile,
            },
            {
                path: "features/",
                name: "Features",
                component: FeatureLayout,
                redirect: "features/trainingmanagement/",
                children: [
                    {
                        path: "trainingmanagement/",
                        name: "TrainingManagement",
                        component: TrainingManagement,
                    },
                ]
            },
        ]
    },
    { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
