<template>
  <div v-if="tableData" class="dataGrid">
    <table id="dataGrid">
      <grid-header
        :columns="columns"
        :tableName="tableName"
        @selectedAll="headerSelectAll"
        @communicateSort="updateSort">
      </grid-header>
      <grid-body
        :columns="columns"
        :sentGridData="sentGridData"
        :filterKey="filterKey"
        :statusKey="statusKey"
        :keyOrder="keyOrder"
        :ariaInformation="ariaInformation"
        :tableName="tableName"
        :checkBoxKey="checkBoxKey"
        :enableEdit="enableEdit"
        :selectedAll="selectAll"
        @showOptions="sendOptionInfo"
        @showFiles="showFiles"
        @checkedValues="updateCheckedVals">
      </grid-body>
    </table>
  </div>
</template>
<script>
import generalUtilMixins from '../../utilities/generalUtilMixins';
import gridMixins from '../../utilities/gridMixins';
import GridHeader from '../Grids/GridHeader.vue';
import GridBody from '../Grids/GridBody.vue';

export default {
  name: 'common-grid',
  components: {
    GridHeader,
    GridBody
  },
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
    statusKey: String,
    checkBoxKey: String,
    endPoint: String,
    orgId: Boolean,
    enableEdit: Boolean,
    enableDelete: Boolean,
    sentGridData: "",
    ariaInformation: Object,
    tableName: {
      type: String,
      default: "check"
    },
  },
  mixins: [generalUtilMixins, gridMixins],
  data: function() {
    return {
      sentSortKey: "",
      tempSortKey: "",
      keyOrder: {},
      tableData: [],
      copiedData: [],
      selectData: [],
      receivedData: [],
      checkedValues: [],
      editedValues: {},
      selectAll: false,
      editCells: null,
      passedCheckboxKey: this.checkBoxKey,
      options: ["checkbox", "files"]
    };
  },
  mounted() {  
    this.receivedData = this.$store.getters.activeCategories;
  },
  computed: {
    
  },
  filters: {
 
  },
  methods: {
    updateSort(key){
      this.tempSortKey = key;
      if (key == this.sentSortKey) {
        this.keyOrder = {
          'key': this.tempSortKey,
          'order': -1
        }
        this.sentSortKey = "";
      } else {
        this.sentSortKey = this.tempSortKey;
        this.keyOrder = {
          'key': this.tempSortKey,
          'order': 1
        }
      }
    }
  },
  watch: {
    enableEdit(newValue) {
        this.editCells = newValue;
        this.getCategories();
        if (newValue == false) {
            this.saveEdittedData();
        }
    },
  }
};
</script>
<style>
</style>
