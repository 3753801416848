<template>
  <print-dropdown-button
    :buttonLabel="buttonLabel"
    @printClicked="createTemplate">
  </print-dropdown-button>
</template>
<script>
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';
import PrintDropdownButton from '../PrintDropdownButton.vue';

export default {
  name: 'print-tableOnlyTrainingRequirements',
  components: {
    PrintDropdownButton
  },
  props: {
    buttonLabel: String,
    sentGridData: "",
    desiredIds: Array,
    headers: Array,
    title: String,
  },
  mixins: [printMixins, printFileMixins],
  data() {
    return {
      showMenu: false,
      courses: []
    };
  },
  mounted() {
    this.courses = this.$store.getters.activeCategories['trainingCourse'];
  },
  methods: {
    async createTemplate () {
      this.docDefinition['pageOrientation'] = "landscape";
      this.docDefinition['content'] = this.makeRequirementTable(this.sentGridData);
      this.pdfMake.createPdf(this.docDefinition).download("optionalName.pdf");
    },
    makeRequirementTable (data) {
      let cleanedBody = [];
      let tempArray = [];
      let rowArray = [];
      let requirementArray = [];
      let tempCoursesArray = JSON.parse(JSON.stringify(this.courses));
      let courseHeaders = [];
      let widthsArray = [];

      tempCoursesArray.unshift("");
      
      for (let i3 in tempCoursesArray) {
        courseHeaders.push({text: tempCoursesArray[i3], style: 'tableHeader'});
        widthsArray.push("*");
      }

      for (let index in data) {
        rowArray.push([{text: this.$options.filters.capitalize(index), style: 'tableSubHeader', colSpan: tempCoursesArray.length}]);
        for (let i in data[index]) {
          tempArray.push({text: data[index][i]["value"]});
          for (let i2 in tempCoursesArray) {
            if (data[index][i]['training'][tempCoursesArray[i2]]) {
              tempArray.push({ text: "X" });
            } else if (!data[index][i]['training'][tempCoursesArray[i2]] && tempCoursesArray[i2] != "") {
              tempArray.push({ text: "" });
            }
          }
          
          rowArray.push(JSON.parse(JSON.stringify(tempArray)));
          tempArray = [];
        }
      }

      //Add courses as headers.
      rowArray.unshift(courseHeaders);

      requirementArray.push(this.buildPageHeader(this.title));

      requirementArray.push(
          {
              style: 'tableExample',
              table: {
                  widths: widthsArray,
                  headerRows: 1,
                  dontBreakRows: true,
                  keepWithHeaderRows: 1,
                  body: rowArray
              },
              id: "trainingTable"
          }
      );    
  
      cleanedBody.push(requirementArray);

      return cleanedBody;
    }
  }
};
</script>