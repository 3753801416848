/*
|-------------------------------------------------------------------------------
| Mixins for use with grids.
|-------------------------------------------------------------------------------
*/
export default {
  data: function() {
    return {
      showModal: false,
      showBulkModal: false,
      showFileModal: false,
    }
  },
  mounted() {

  },
  methods: {
    openFileModal (sentId){
      this.showFileModal = true;
      this.fileIdtoSend = sentId;
      this.hideMainContent();
    },
    openModal (varName) {
      this[varName] = true;
      this.hideMainContent();
    },
    closeModal (varName) {
      this[varName] = false;
      document.getElementById("mainNav").removeAttribute('aria-hidden');
      document.getElementById("userNav").removeAttribute('aria-hidden');
      document.getElementById("mainContent-container").removeAttribute('aria-hidden');
      document.documentElement.style.overflow = 'auto';
      document.body.classList.remove("noScroll");
    },
    hideMainContent () {
      document.getElementById("mainNav").setAttribute('aria-hidden', 'true');
      document.getElementById("userNav").setAttribute('aria-hidden', 'true');
      document.getElementById("mainContent-container").setAttribute('aria-hidden', 'true');
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add("noScroll");
    }
  }
};

