/*
|-------------------------------------------------------------------------------
| Services for General Axios Endpoint Queries
|-------------------------------------------------------------------------------
*/
import axios from "axios";
import * as helpers from "../../utilities/helperUtils";

const instance = axios.create({
  baseURL: helpers.getEnvironmentVar("VUE_APP_BASE_URL", "") + "/be/"
});

export default instance;
