<template>
    <div class="fileDrop-container">
        <div id="fileDrop-area" v-cloak @drop.prevent="handleFileDrop" @dragover.prevent>
          <svg-icon-container
            focusable="false" 
            :icon="'uploadIcon'">
          </svg-icon-container>
          <div class="fileDrop-area-actions">
            <span>Drag and drop files here!</span>
            <input type="file" id="file-input" name="file-input" multiple="True" :accept="acceptedFormats" @change="handleFileInput" aria-hidden="true" hidden />
            <button class="btn btn-outline-default" aria-label="Open file browser" @click="openDialogue">Browse</button>
          </div>
        </div>
        <div v-show="files.length > 0" id="filesUpload-new">
          <h5 id="filesUpload-label">Files Being Uploaded</h5>
        </div>
        <ul aria-labelledby="filesUpload-label">
          <li v-for="file in files">
            {{ file.name }} ({{ file.size | kb }} kb) 
            <button
              class="btn-naked" 
              aria-label="Remove file from upload" @click="removeFile(file)" title="Remove">
              <svg-icon-container
                :icon="'xOnlyIcon'">
              </svg-icon-container>
            </button>
          </li>
        </ul>
        <div v-show="this.$slots.existingFileGrid && !!this.$slots.existingFileGrid[0]" id="filesUpload-new">
          <h5 id="filesNew-label">Existing Files</h5>
        </div>
        <slot name="existingFileGrid"></slot>
    </div>
</template>
<script>
import SvgIconContainer from "./Svg/SvgIconContainer.vue";

export default {
  name: 'file-drop',
  components: {
    SvgIconContainer
  },
  props: {
    acceptedFormats: {
      type: String,
      default: ".pdf"
    }
  },
  data: function() {
    return {
      files: [],
    };
  },
  mounted() {  

  },
  filters: {
    kb: function(val) {
        return Math.floor(val/1024);
    }
  },
  computed: {
    
  },
  methods: {
    openDialogue () {
      document.getElementById('file-input').click();
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files;
      if(!droppedFiles) return;
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
    },
    handleFileInput(e) {
      let files = e.target.files
      if(!files) return;
      ([...files]).forEach(f => {  
        this.files.push(f);
      });
    },
    removeFile(file){
      this.files = this.files.filter(f => {
        return f != file;
      });      
    }    
  },
  watch: {
    files () {
      this.$emit('selectedFiles', this.files);
    }
  }
};
</script>
<style>
</style>
