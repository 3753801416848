import store from '../store/store';

export function orgParam() {
    let orgId = orgParamBase()
    if (orgId != "") {
        return "orgId=" + orgParamBase();
    } else {
        return "";
    }
}

export function orgParamBase() {
    return store.state.org.requestedOrg;
}
  


