<template>
  <nav id="userNav" class="navbar navbar-expand-lg navbar-light flex-row" aria-label="User Specific Navigation">
    <router-link to="/" exact>
        <svg-icon-container
        class="flex-left logo"
        focusable="false" 
        :icon="'hseAllianceIcon'">
        </svg-icon-container> 
    </router-link>
    <div class="flex-row flex-right btn-container">
      <!--<button class="btn btn-outline-default">Button</button>-->
      <router-link class="btn btn-outline-default flex-right" as="button" :to="{ name: 'Features'}">
          Features
      </router-link>  
      <router-link class="btn btn-outline-default flex-right" as="button" :to="{ name: 'TrainingProfile'}">
          Public Search
      </router-link>
      <a class="btn btn-success flex-right" :href="hubUrl">Login</a>
    </div>
    <button class="navbar-toggler navbar-burger"
            type="button"
            @click="toggleSidebar"
            :aria-expanded="$sidebar.showSidebar"
            aria-label="Toggle mobile navigation">
      <span class="navbar-toggler-bar"></span>
      <span class="navbar-toggler-bar"></span>
      <span class="navbar-toggler-bar"></span>
    </button>
  </nav>
</template>
<script>
import {SvgIconContainer} from "@/components";

export default {
  components: {
    SvgIconContainer
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }, 
    hubUrl() {
        if (process.env.VUE_APP_HUB_URL) {
            return "http://" + process.env.VUE_APP_HUB_URL + '/hub/login/';
        } else {
            return window.location.origin + "/hub/login/";
        }
    }
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
        this.$store.dispatch("auth/logout")
    }
  }
};
</script>
<style>
</style>
