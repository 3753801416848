<template>
  <div>
    <section class="legal-hero esg">
        <div class="flex-row section-container flex-justify-center">
            <div class="hero-svg">
                <svg-icon-container
                    :hidden="false"
                    :icon="'trainingIcon'"
                    :title="'Settings'">
                </svg-icon-container>
            </div>
            <div class="hero-txt">
                <h1 class="legal-title">Public Profile</h1>
            </div>
        </div>
    </section>
    <section class="legal search">
        <div class="flex-row section-container content-container search">
            <div class="flex-column">
                <label for="searchHubUUID">Search HubUUID: </label>
            </div>
            <div class="flex-column">
                <input id="searchHubUUID" 
                    class="form-control" 
                    name="query" 
                    v-model="hubQuery" 
                    placeholder="Enter the HubUUID you would like to search."
                    v-on:keydown.enter.prevent='searchHubID'/>
            </div>
        </div>
    </section>
    <section class="legal">
        <div class="flex-row section-container content-container">
            <card>
                <h2 class="card-h2" slot="header">Training Completed</h2>
                <div slot="raw-content">
                    <data-grid-container
                                :columns="gridColumns"
                                :endPoint="trainingTable.url"
                                :sentGridData="trainingData"
                                :filterKey="searchQuery"
                                :gridName="'common-grid'"
                                :checkBoxKey="trainingTable.tableKey"
                                :printOptions="printOptions"
                                :ariaInformation="tableAriaInfo"
                                :hubUUID="hubQuery"
                                :editableTable="false">
                    </data-grid-container>
                </div>
            </card>
        </div>
    </section>
  </div>
</template>
<script>
import Privacy from '../components/html/Privacy.html'
import TermsOfService from '../components/html/TermsOfService.html'
import SvgIconContainer from '../components/Svg/SvgIconContainer.vue';
import DataGridContainer from '../components/DataGridContainer.vue';
import generalServices from '../services/GeneralServices';

export default {
    components: {
        SvgIconContainer,
        DataGridContainer
    },
    mixins: [],
    data() {
        return {
            Privacy,
            TermsOfService,
            activeAccordion: "",
            showModal: false,
            windowWidth: "",
            htmlTemplate: null,
            navArray: [],
            templateName: "",
            legalTitle: "",
            revisionDate: "",
            policyType: "",
            trainingData: {},
            searchQuery: "",
            hubQuery: "",
            trainingTable: {
                url: "training/",
                getUrl: "",
                fileKey: "training",
                postId: ""
            },
            gridColumns: [
                {name: "checkbox", type: "checkbox", editable: false},
                {name: "trainingCourse", type: "select", editable: true},
                {name: "trainingProvider", type: "select", editable: true},
                {name: "dateIssued", type: "date", editable: true},
                {name: "dateExpired", type: "date", editable: true},
                {name: "status", type: "select", editable: true},
                {name: "files", type: "viewFiles", editable: false}
            ],
            printOptions: {
                title: "",
                options: [
                    {template: "print-tableOnly", buttonLabel: "Table Only"},
                    {template: "print-publicImagesOnly", buttonLabel: "Images Only"},
                    {template: "print-publicTablesWithImages", buttonLabel: "Tables with Images"}
                ]
            },
            tableAriaInfo: {
                tableCollection: "training",
                specificRecordKey: "trainingCourse",
            },
        };
    },
    created () {
        
    },
    computed: {

    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.getPublicTrainingInfo();
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    filters: {
        
    },
    methods: {
        accordionTarget(clickedHeader){
            if (this.windowWidth < 991){
                if (this.activeAccordion == clickedHeader){
                    this.activeAccordion = "";
                } else {
                    this.activeAccordion = clickedHeader;
                }
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth > 991){
                this.activeAccordion = "all";
            } else {
                this.activeAccordion = "";
            }
        },
        cleanHtml(htmlToClean) {
            let tempObject = {};
            let tempKey = "";
            for ( var key in htmlToClean ) {
                if (htmlToClean[key].tagName == "H1") {
                    tempKey = htmlToClean[key].innerHTML;
                    tempObject[tempKey] = [];
                } else if (htmlToClean[key].tagName == "TITLE"){
                    console.log(htmlToClean[key].innerHTML);
                    this.legalTitle = htmlToClean[key].innerHTML;
                } else if (htmlToClean[key].tagName == "DETAILS"){
                    this.revisionDate = htmlToClean[key].innerHTML;
                } else if (htmlToClean[key].tagName == "SUMMARY"){
                    this.policyType = htmlToClean[key].innerHTML;
                } else {
                    tempObject[tempKey] = tempObject[tempKey] + htmlToClean[key].outerHTML;
                }
            }

            return tempObject;
        },
        async getPublicTrainingInfo(){
            if (this.hubQuery == "") {
                this.hubQuery = this.$route.query.hubUUID;
            }

            try {
                let trainingInfo = await generalServices.GET_DATA("public/training/" + this.$route.query.hubUUID, this.queryParams);

                this.trainingData = trainingInfo.data;
                
            } catch (err) {
                this.trainingData = [];
                console.log(err);
            }
        },
        searchHubID() {
            this.$router.push({ path: "/public/training/", query: { 'hubUUID': this.hubQuery } });
            this.getPublicTrainingInfo();
        }
    },
    watch:{

    } 
};</script>
<style lang="scss">
</style>
