<template>
    <div class="eventMessage-body">
        <template v-if="checkSuccessful(httpCode)">
            <svg-icon-container 
              focusable="false"
              :icon="'successFaceIcon'">
            </svg-icon-container>
            <h2>Success!</h2>
            <p>{{httpMessage}}</p>
        </template>
        <template v-else>
            <svg-icon-container 
              focusable="false"
              :icon="'errorFaceIcon'">
            </svg-icon-container>
            <h2>Uh oh!</h2>
            <p>{{httpMessage}}</p>
            <template v-if="Object.keys(fullStopValues).length > 0">
                <ul>
                    <li v-for="(item, index) in fullStopValues">
                        {{item}}
                    </li>
                </ul>
            </template>
            <template v-if="Object.keys(errorsForDownload).length > 0">
                <button class="btn btn-success" @click="cleanAndDownloadSheet(errorsForDownload)">
                   Download sheet with errors
                </button>
            </template>
        </template>
        <button class="btn btn-outline-default" @click="$emit('close')">
            Got it!
        </button>
    </div>
</template>
<script>
import SvgIconContainer from "./Svg/SvgIconContainer.vue";

export default {
  name: 'success-error-message',
  components: {
    SvgIconContainer,
  },
  props: {
    httpCode: Number,
    httpMessage: String,
    fullStopValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    errorsForDownload: {
      type: Object,
      default: function () {
        return {};
      }
    },
  },
  data: function() {
    return {
      files: [],
    };
  },
  mounted() {  

  },
  methods: {
    checkSuccessful (value) {
        return /^(2)/.test(value);
    },
    cleanAndDownloadSheet(obj) {
        let csvString = "";
        for (let index in obj) {
          let tempString = JSON.stringify(obj[index]).replace(/[\[\]"]+/g, '');
          csvString = csvString + tempString + "\r\n";
        }
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
        hiddenElement.target = '_blank';
        hiddenElement.download = this.collection + '.csv';
        hiddenElement.click();
    } 
  },
  watch: {
    
  }
};
</script>
<style>
</style>
