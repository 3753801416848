<template>
  <div v-if="tableData && trainingCourses">
    <div class="dataGridContainer">
      <div class="buttonOptions">
        <slot name="additionalButtons"></slot>
        <div class="dataGridButtons">
          <button type="button" class="btn btn-outline-default" :aria-label="((!editCells) ? 'edit' : 'save')"
                  @click="editData">
            <svg-icon-container 
              v-if="!editCells"
              focusable="false"
              :icon="'editIcon'">
            </svg-icon-container>
            <svg-icon-container 
              v-else
              focusable="false"
              :icon="'saveIcon'">
            </svg-icon-container>
          </button>
          <print-dropdown
            :headers="headers"
            :sentGridData="sentGridData"
            :desiredIds="checkedValues"
            :printOptions="printOptions">
          </print-dropdown>
        </div>
      </div>
      <div class="dataGrid">
        <table>
          <thead>
            <tr>
              <template v-for="key in trainingCourses">
                <td v-if="(key == '')" scope="col" :id="key">
                  {{ key }}
                </td>
                <th v-else scope="col" :tabindex="((key == '') ? '' : '0')" :id="key">
                  {{ key }}
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-if="Object.keys(tableData).length < 1">
                <tr>
                    <td colspan="">
                        No information found.
                    </td>
                </tr>
            </template>
            <template v-else v-for="(entry, index) in tableData">
              <tr class="subheader">
                <template v-for="key in trainingCourses">
                  <th v-if='key == ""' tabindex="0" :id="index" scope="colgroup">
                    {{index | capitalize}}
                  </th>
                  <td tabindex="0" v-else>
                    <svg-icon-container
                      v-show="!editCells"
                      v-if="checkIfContains(index, key) == 'full'"
                      :hidden="false"
                      :icon="'fullCircleIcon'"
                      :title="key + ' is a requirement for all ' + index + 's'">
                    </svg-icon-container>
                    <svg-icon-container
                      v-show="!editCells"
                      v-if="checkIfContains(index, key) == 'partial'"
                      :hidden="false"
                      :icon="'halfCircleIcon'"
                      :title="key + ' is a requirement for some ' + index + 's'">
                    </svg-icon-container>
                    <svg-icon-container
                      v-show="!editCells"
                      v-if="checkIfContains(index, key) == 'empty'"
                      :hidden="false"
                      :icon="'emptyCircleIcon'"
                      :title="key + ' is not a requirement for any ' + index + 's'">
                    </svg-icon-container>
                    <template>
                      <input v-show="editCells" type="checkbox" name="tableId" :id="key + index"
                        v-model="subHeaders[index][key]"
                        :aria-label="((subHeaders[index][key]) ? 'Deselect to remove ' + key + ' training as a requirement for all ' + index + 's': 
                          'Select to add ' + key + ' training as a requirement for all ' + index + 's')"
                        @change="selectAllSubs(index, key)">
                      <label v-show="editCells" class="sreader-only" :for="key + index">{{(subHeaders[index][key]) ? 'Deselect to remove ' + key + ' training as a requirement for all ' + index + 's': 
                          'Select to add ' + key + ' training as a requirement for all ' + index + 's'}}</label>
                    </template>
                  </td>
                </template>
              </tr>
              <tr v-for="(item, index2) in entry">
                <template v-for="name in trainingCourses">
                  <th v-if='name == ""' tabindex="0" scope="row" :id="item.value" :headers="index">
                    {{item.value}}
                  </th>
                  <td v-else tabindex="0" :headers="index + ' ' + item.value + ' ' + name">
                    <svg-icon-container
                      v-show="!editCells"
                      v-if="item['training'][name] == true"
                      :hidden="false"
                      :icon="'checkmarkOnlyIcon'"
                      :title="name + ' training is a requirement for the ' + item.value + ' ' + index">
                    </svg-icon-container>
                    <input v-show="editCells" type="checkbox" name="tableId" :id="name + item.value + index"
                      v-model="item['training'][name]"
                      :aria-label="((item['training'][name]) ? 'Deselect to remove ' + name + ' training as a requirement for the ' + item.value + ' ' + index : 
                        'Select to add ' + name + ' training as a requirement for the ' + item.value + ' ' + index)">
                    <label v-show="editCells" class="sreader-only" :for="name + item.value + index">{{(item['training'][name]) ? 'Deselect to remove ' + name + ' training as a requirement for the ' + item.value + ' ' + index : 
                        'Select to add ' + name + ' training as a requirement for the ' + item.value + ' ' + index}}</label>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import PrintDropdown from "./PrintDropdown";
import generalServices from '../services/GeneralServices';
import SvgIconContainer from "./Svg/SvgIconContainer.vue";
import { orgParamBase } from '../utilities/orgParam';

export default {
  name: 'requirement-grid',
  components: {
    PrintDropdown,
    SvgIconContainer,
  },
  props: {
    heroes: Array,
    headers: Array,
    filterKey: String,
    checkBoxKey: String,
    endPoint: String,
    enableEdit: Boolean,
    sentGridData: "",
    printOptions: Object
  },
  data: function() {
    return {
      tableData: [],
      copiedData: [],
      checkedValues: [],
      trainingCourses: [],
      subHeaders: {},
      editCells: false,
      selectAll: false,
      editCells: null,
      options: ["checkbox", "files"]
    };
  },
  mounted() {  
    this.receivedData = this.$store.getters.activeCategories;
  },
  computed: {

  },
  filters: {

  },
  methods: {
    checkIfContains (key, course) {
      let count = 0;
      let objSize = Object.keys(this.tableData[key]).length;
      let outcome = "";
      for (let i in this.tableData[key]) {
        if (this.tableData[key][i]['training'][course]) {
          count++;
        }
      }

      if (count == 0) {
        outcome = "empty";
        this.subHeaders[key][course] = false;
      } else if (count < objSize) {
        outcome = "partial";
        this.subHeaders[key][course] = false;
      } else {
        outcome = "full";
        this.subHeaders[key][course] = true;
      }

      return outcome;
    },
    editData() {
      if (!this.editCells){
        this.editCells = true;
      } else {
        this.editCells = false;
        this.saveEdittedData();
      }
    },
    loadData() {
      if (this.copiedData.length === 0) {
        this.tableData = this.sentGridData;

        for (let i in this.tableData) {
          for (let i2 in this.tableData[i][0]['training']) {
            this.trainingCourses.push(i2);
          }

          this.trainingCourses.unshift("");
          this.trainingCourses.sort();
          break;
        }

        for (let key in this.tableData) {
          this.subHeaders[key] = {};
          for (let i in this.trainingCourses) {
            this.subHeaders[key][this.trainingCourses[i]] = false;
          }
        }
      }      

      this.copiedData = JSON.parse(JSON.stringify(this.tableData));
    },
    selectAllSubs(index, course) {
      let checkAll = "";
      if (Object.values(this.tableData[index]).some(e => e['training'][course] === false)) {
        checkAll = true;
      } else {
        checkAll = false;
      }

      for (let i in this.tableData[index]) {
        this.tableData[index][i]['training'][course] = checkAll;
      }
    },
    async saveEdittedData(){
      let valueIndexes = this.objectDiff(this.copiedData, this.tableData);
      let valueObject = {};
      let queryParams = {
            'orgId': orgParamBase()
        };

      if (valueIndexes) {
        for (let key in valueIndexes) {
          
          for (let index in valueIndexes[key]) {
            if (valueIndexes[key][index]) {

              for (let i in valueIndexes[key][index]) {
                valueObject['trainingCourse'] = valueIndexes[key][index][i]['trainingCourse'];
                valueObject[key] = valueIndexes[key][index][i]['value'];
                if (index == 'toDelete') {
                  try {
                    let response = await generalServices.DELETE_WJSON(this.endPoint, valueObject, queryParams);
                  } catch (err) {
                    console.log(err);
                  }
                } else {
                  try {
                    let response = await generalServices.POST_DATA(this.endPoint, valueObject, queryParams);
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            }
          }
        }
      }

      this.loadData();
      
    },
    objectDiff(a, b) {
      let diffKeys = [];
      let actionsObject = {};
      for (let i in a) {
        if (JSON.stringify(a[i]) != JSON.stringify(b[i])) {
          diffKeys.push(i);
        }
      }

      if (diffKeys) {
        for (let key in diffKeys) {
          actionsObject[diffKeys[key]] = {};
          actionsObject[diffKeys[key]]['toDelete'] = {};
          actionsObject[diffKeys[key]]['toAdd'] = {};
          let y = 0;
          for (let i in this.tableData[diffKeys[key]]) {
            
            //TODO: add i to the actions? Not capturing all the items, just training courses.
            for (let course in this.tableData[diffKeys[key]][i]['training']) {
              if (this.tableData[diffKeys[key]][i]['training'][course] != this.copiedData[diffKeys[key]][i]['training'][course]) {
                if (this.tableData[diffKeys[key]][i]['training'][course]){
                  actionsObject[diffKeys[key]]['toAdd'][y] = {'trainingCourse': course, 'value': this.tableData[diffKeys[key]][i]['value']};
                } else {
                  actionsObject[diffKeys[key]]['toDelete'][y] = {'trainingCourse': course, 'value': this.tableData[diffKeys[key]][i]['value']};
                }
              }
              y++;
            }
          }
        }
      }
      
      return actionsObject;
    }
  },
  watch: {
    sentGridData() {
        this.copiedData = [];
        this.tableData = [];
        this.trainingCourses= [];
        this.subHeaders= {};
        this.loadData();
    }
  }
};
</script>
<style>
</style>
