<template>
  <div>
    <section>
        <div class="flex-row section-container">
            <div class="flex-column flex-1">
                <h1>Simplifying your digital health and safety processes</h1>
                <p class="h1-desc">
                    Our belief is that occupational health and safety software can be built to simplify diverse processes related to administration and compliance requirements.
                </p>
                <div class="flex-row flex-left btn-container">
                    <button class="btn btn-success flex-right" @click="requestDemo = true; openModal('showModal')">Request demo</button>
                    <!--<a class="btn btn-outline-default flex-right" href="https://develop.hsealliance.ca/login/">More about us</a>-->
                </div>
            </div>
            <div class="flex-column flex-column-2 svg-container flex-item-end">
                <svg-icon-container
                    :hidden="false"
                    :icon="'heroImage'"
                    class="mainHeroSvg">
                </svg-icon-container>
            </div>
        </div>
    </section>
    <section class="hubGreen">
        <div class="flex-row section-container">
            <div class="flex-column flex-item-center flex-grow card-wrapper">
                <h2>Introducing HSE HUB</h2>
                <p class="card-summary">One integrated SaaS based platform to exceed the health and safety needs of the industry. From capturing compliance requirements to saving you time and resources, HSE HUB allows you to place focus on what is important to you allowing more time to be spent on improving your overall safety culture.</p>
                <div class="flex-row flex-row-gap card-container">
                    <template v-for="item in cards">
                        <card class="landing-cards neutral" :title="item.title">
                            <svg-icon-container
                                slot="headerSVG"    
                                :hidden="false"
                                :icon="item.svg">
                            </svg-icon-container>
                            <p class="card-text" slot="default">
                                {{item.description}}
                            </p>
                        </card>
                    </template>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="flex-row section-container">
            <div class="flex-column flex-1">
                <h2>Current Features</h2>
                <div class="flex-row btn-container features">
                <button 
                    class="btn btn-outline-default"
                    :class="(activeFeature == 'training')?'active':''"
                    @click="activeFeature = 'training'">
                    <div class="flex-column">
                    <svg-icon-container
                        :hidden="false"
                        :icon="'trainingIcon'">
                    </svg-icon-container>Training Management
                    </div>
                </button>
                </div>
                <p>HSE HUB's current training management feature allows individual users and organizations to fulfill their needs, such as:</p>
                <div v-if="activeFeature === 'training'">
                    <ul class="hi-ul odd">
                        <li>
                            <svg-icon-container
                                class="hi-checkmark" 
                                :hidden="false"
                                :icon="'checkmarkIcon'"
                                :title="'Has Training'">
                            </svg-icon-container>
                            <span>Upload training record information, including images</span>
                        </li>
                        <li>
                            <svg-icon-container
                            class="hi-checkmark" 
                            :hidden="false"
                            :icon="'checkmarkIcon'"
                            :title="'Has Training'">
                            </svg-icon-container>
                            <span>Share or print records quickly and easily</span>
                        </li>
                        <li>
                            <svg-icon-container
                            class="hi-checkmark" 
                            :hidden="false"
                            :icon="'checkmarkIcon'"
                            :title="'Has Training'">
                            </svg-icon-container>
                            <span>Track organization training requirements</span>
                        </li>
                        <li>
                            <svg-icon-container
                            class="hi-checkmark" 
                            :hidden="false"
                            :icon="'checkmarkIcon'"
                            :title="'Has Training'">
                            </svg-icon-container>
                            <span>Notifications for expiring or expired records</span>
                        </li>
                        <li>
                            <svg-icon-container
                            class="hi-checkmark" 
                            :hidden="false"
                            :icon="'checkmarkIcon'"
                            :title="'Has Training'">
                            </svg-icon-container>
                            <span>AND MORE!</span>
                        </li>
                    </ul>
                    <ul v-if="activeFeature === 'test'" class="hi-ul">
                        <li>
                            <svg-icon-container
                                class="hi-checkmark" 
                                :hidden="false"
                                :icon="'checkmarkIcon'"
                                :title="'Has Training'">
                            </svg-icon-container>
                            <span>Test</span>
                        </li>
                    </ul>
                    <div class="flex-row flex-left btn-container">
                        <router-link class="btn btn-outline-default" as="button" :to="{ name: 'TrainingManagement'}">Learn More</router-link>
                    </div>
                </div>
            </div>
            <div class="flex-column flex-column-2 flex-item-end svg-container">
                <svg-icon-container
                    :hidden="false"
                    :icon="'trainingManagement'">
                </svg-icon-container>
            </div>
        </div>
    </section>
    <section class="flex-row light-gray">
      <div class="flex-column flex-1 flex-item-center section-container search">
        <h2>Want to see more?</h2>
        <button class="btn btn-success" @click="requestDemo = true; openModal('showModal')">Request demo</button>
      </div>
    </section>
    <contact-modal v-if="showModal"
                  :inputs="modalInputs"
                  :demo="true"
                  @close="requestDemo = false; closeModal('showModal')">
      <h2 slot="header">Contact Us</h2>
    </contact-modal>
  </div>
</template>
<script>
import { SvgIconContainer } from "@/components";
import { ContactUsModal } from "@/components";
import modalEventMixins from '../utilities/modalEventMixins';

export default {
  components: {
    'svg-icon-container': SvgIconContainer,
    'contact-modal': ContactUsModal
  },
  mixins: [modalEventMixins],
  data() {
    return {
      activeFeature: "training",
      showModal: false,
      requestDemo: false,
      modalInputs: [
        {type: "email", category: "fromEmail", required: true, alternateLabel: "Your Email"},
        {type: "text", category: "subject", required: true},
        {type: "textarea", category: "message", required: true},
        {type: "textarea", category: "userComments"},
      ],
      cards: [
        {
            'svg': 'clientDrivenIcon',
            'title': 'Client and expert driven',
            'description': "Our platform is deeply rooted in the industry as its development takes into account valuable feedback from a community of health, safety and environmental (HSE) practitioners / professionals, our clients and individual users."
        },
        {
            'svg': 'connectionIcon',
            'title': 'Connection of features and data',
            'description': "We help you reduce the need for excessive administrative resources and potentially quality issues by connecting our features and data together, as well as through our various sharing options."
        },
        {
            'svg': 'keepItSimpleIcon',
            'title': 'Keeping it simple',
            'description': "Don't like over complication? Neither do we. We aim to make our platform easy to learn, use and to navigate with the fewest clicks possible."
        },
        {
            'svg': 'removeBarrierIcon',
            'title': 'Removing barriers through accessibility',
            'description': "We work with you to understand and remove potential barriers that may prevent you from accessing or using our platform."
        },
      ],
    };
  },
  created () {
    
  },
  mounted() {
    
  },
  filters: {
    
  },
  computed: {

  },
  methods: {
    
  }
};</script>
<style lang="scss">
</style>
