<template>
  <nav id="mainNav" class="sidebar" aria-label="Main Navigation"
  @keyup.down.prevent="moveDownMenu"
                    @keydown.down.prevent
                    @keyup.up.prevent="moveUpMenu"
                    @keydown.up.prevent>
    <div class="sidebar-wrapper" id="style-3">
      <template v-for="(main, mainIndex) in menu">
            <div class="nav-category" v-show="!main.hide">
                <ul class="nav" tabindex="0">
                    <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
                    <li
                        class="nav-item flex-row"
                        tabindex="-1">
                        <a class="nav-link flex-row" 
                            tabindex="-1"
                            role="menuItem"
                            :href="hubUrl">
                            <svg-icon-container
                                focusable="false" 
                                :icon="icon">
                            </svg-icon-container>
                            <p focusable="false">Login</p>
                        </a>
                    </li>
                    <template v-for="(item, index) in main.items">
                        <template v-if="!item.groupName">
                            <sidebar-link 
                            :to="item.to" 
                            :name="item.name" 
                            :icon="item.icon" 
                            @groupInfo="subMenuClick"/>
                        </template>
                        <template v-else>
                            <div class="nav-item flex-row" :class="(isActivePath(item.children) ? 'active' : '')">
                                <button class="nav-link flex-row"
                                    tabindex="-1"
                                    @click="item.show = !item.show"
                                    aria-haspopup="true"
                                    :aria-expanded="(item.show ? 'true' : 'false')"
                                    :ref="'mainMenu'">
                                    <svg-icon-container
                                        focusable="false" 
                                        :icon="item.icon">
                                    </svg-icon-container>
                                    {{item.groupName}}
                                </button>
                            </div>
                            <transition name="fade">
                                <ul class="nav nav-sub-menu" tabindex="0"
                                    v-show="item.show || isActivePath(item.children)">
                                    <template v-for="(children, childIndex) in item.children">
                                            <sidebar-link 
                                                class="nav-sub-item"
                                                :to="{path: children.to}" 
                                                :name="children.name" 
                                                :icon="children.icon"
                                                :menuIndex="mainIndex"
                                                :groupIndex="index"
                                                @groupInfo="subMenuClick">
                                            </sidebar-link>
                                    </template>
                                </ul>
                            </transition>
                        </template>
                    </template>
                </ul>
            </div>
      </template>
    </div>
  </nav>
</template>
<script>
import SidebarLink from "./SidebarLink";
import orgMixins from "../../utilities/orgMixins";
import SvgIconContainer from "../Svg/SvgIconContainer.vue";

export default {
  props: {
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    };
  },
  components: {
    SidebarLink,
    SvgIconContainer
  },
  mixins: [orgMixins],
  data() {
    return {
      menuPosition: null,
      logo: "",
      lastGroupIndex: null,
      lastMenuIndex: null,
      navItems: [],
      menu: [
        {
            title: "General",
            hide: false,
            items: [
                {
                    name: "Public Search",
                    icon: "",
                    to: "/public/training/"
                },
                {
                    name: "Features",
                    icon: "",
                    to: "/features/"
                }
            ]
        }
      ]
    };
  },
  computed: {
    isUserPage(){
        return (this.$route.name === 'Organizations' || this.$route.name === 'UserProfile');
    },
    orgLogo() {
        return this.$store.getters.activeOrgLogo;
    },
    orgName() {
        return this.$store.getters.activeOrgName;
    },
    orgId() {
        return this.$store.getters.activeOrg;
    },
    hubUrl() {
        if (process.env.VUE_APP_HUB_URL) {
            return "http://" + process.env.VUE_APP_HUB_URL + "/hub/login/";
        } else {
            return window.location.origin + "/hub/login/";
        }
    } 
  },
  mounted() {
    this.checkOrgId();
    this.navItems = document.getElementById('mainNav').getElementsByClassName('nav-link');
  },
  methods: {
    getNextItem () {
        if (this.menuPosition == null) {
            this.menuPosition = 0;
        } else {
            this.menuPosition = this.menuPosition +1;
        }

        if (!this.navItems[this.menuPosition]) {
            return null;
        } else {
            if (window.getComputedStyle(this.navItems[this.menuPosition].closest("div")).display === "none") {
                let nextSibling = this.navItems[this.menuPosition].closest("div").nextElementSibling;
                if (nextSibling == null) {
                    this.menuPosition = 0;
                    return this.navItems[0]; 
                } else {
                   if (window.getComputedStyle(nextSibling).display === "none") {
                       let numItems = nextSibling.getElementsByClassName('nav-link').length;
                       this.menuPosition = this.menuPosition + numItems - 1;
                       this.getNextItem();
                   } else {
                       let siblingItems = nextSibling.getElementsByClassName('nav-link');
                       let currentItemCount = this.navItems[this.menuPosition].closest("div").getElementsByClassName('nav-link').length;
                       this.menuPosition = this.menuPosition + currentItemCount;
                       return siblingItems[0];
                   }
                }
            }
            
            if (window.getComputedStyle(this.navItems[this.menuPosition].parentNode.parentNode).display === "none") {
                this.menuPosition = this.menuPosition +1 ;
                this.getNextItem();
            }
            
            return this.navItems[this.menuPosition];
        }
    },
    getPreviousItem () {
        if (this.menuPosition == null) {
            this.menuPosition = this.navItems.length - 1;
        } else {
            this.menuPosition = this.menuPosition - 1;
        }

        if (!this.navItems[this.menuPosition]) {
            return null;
        } else {
            if (window.getComputedStyle(this.navItems[this.menuPosition].closest("div")).display === "none") {
                let previousSibling = this.navItems[this.menuPosition].closest("div").previousElementSibling;
                if (previousSibling == null) {
                    this.menuPosition = this.navItems.length - 1;
                    return this.navItems[this.menuPosition]; 
                } else {
                   if (window.getComputedStyle(previousSibling).display === "none") {
                       let numItems = previousSibling.getElementsByClassName('nav-link').length;
                       this.menuPosition = this.menuPosition - numItems + 1;
                       this.getPreviousItem();
                   } else {
                       let siblingItems = previousSibling.getElementsByClassName('nav-link');
                       let siblingItemsCount = siblingItems.length;
                       let currentItemCount = this.navItems[this.menuPosition].closest("div").getElementsByClassName('nav-link').length;
                       this.menuPosition = this.menuPosition - currentItemCount;
                       return siblingItems[siblingItemsCount - 1];
                   }
                }
            }
            
            if (window.getComputedStyle(this.navItems[this.menuPosition].parentNode.parentNode).display === "none") {
                this.menuPosition = this.menuPosition -1 ;
                this.getPreviousItem();
            }
            
            return this.navItems[this.menuPosition];
        }
    },
    moveDownMenu () {           
        let item = this.getNextItem();
        if (item == null) {
            this.menuPosition = null;
            this.moveDownMenu();
        } else {
            item.focus();
        }
    },
    moveUpMenu () {
        let item = this.getPreviousItem();
        if (item == null) {
            this.menuPosition = null;
            this.moveUpMenu();
        } else {
            item.focus();
        }
    },
    subMenuClick (menuIndex, groupIndex){
        if (menuIndex == null || groupIndex == null) {
            if (this.lastGroupIndex != null) {
                this.menu[this.lastMenuIndex]['items'][this.lastGroupIndex]['show'] = false;
            }
        } else {
            if (this.lastGroupIndex != null && this.lastGroupIndex != groupIndex) {;
                this.menu[this.lastMenuIndex]['items'][this.lastGroupIndex]['show'] = false;
            } 
            this.lastGroupIndex = groupIndex;
            this.lastMenuIndex = menuIndex;
        }
    },
    checkOrgId() {
        if (this.orgId != "") {
            this.menu.forEach(function (arrayItem) {
                if (arrayItem.title == "Organization") {
                    arrayItem.hide = false;
                }
            });
        }
    },
    isActivePath(objectArray) {
        if (objectArray.some(e => e.to === this.$router.currentRoute.path)) {
            return true;
        }
    }
  },
  watch: {
    orgId() {
      this.checkOrgId();
    },
  }
};
</script>
<style>

</style>
