<template>
  <print-dropdown-button
    :buttonLabel="buttonLabel"
    @printClicked="createTemplate">
  </print-dropdown-button>
</template>
<script>
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';
import PrintDropdownButton from '../PrintDropdownButton.vue';

export default {
  name: 'print-imagesOnly',
  components: {
    PrintDropdownButton
  },
  props: {
    buttonLabel: String,
    sentGridData: "",
    desiredIds: Array,
    headers: Array,
    title: String,
    hubUUID: String,
  },
  mixins: [printMixins, printFileMixins],
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    async createTemplate () {
      let filteredArray = this.filterArray (this.sentGridData, this.desiredIds);
      let ids = this.pullIds(filteredArray);
      await this.getFileInfo(ids);

      this.docDefinition['content'] = [this.resultPdf]
      this.resultPdf = [];
      this.pdfMake.createPdf(this.docDefinition).download(this.hubUUID + " - Training Records.pdf");
    },
  }
};
</script>