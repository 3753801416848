/*
|-------------------------------------------------------------------------------
| Mixins for use with grids.
|-------------------------------------------------------------------------------
*/
import generalServices from '../services/GeneralServices';
import { orgParamBase } from "./orgParam"

export default {
    data: function() {
      return {
        sortKey: "",
      }
    },
    computed: {
        filteredData: function() {
            var sortKey = this.sortKey;
            var filterKey = this.filterKey && this.filterKey.toLowerCase();
            var tempStatusKey = this.statusKey;
            var heroes = this.tableData;
        
            if (filterKey) {
                if (Array.isArray(heroes)) {
                    heroes = heroes.filter(function(row) {
                        return Object.keys(row).some(function(key) {
                            return (
                            String(row[key])
                                .toLowerCase()
                                .indexOf(filterKey) > -1
                            );
                        });
                    });
                } else {
                    heroes = Object.values(heroes).filter(function(row) {
                        return Object.keys(row).some(function(key) {
                            return (
                                String(row[key])
                                .toLowerCase()
                                .indexOf(filterKey) > -1
                            );
                        });
                    });
                }
            }
    
            if (sortKey) {
              heroes = heroes.slice().sort(function(a, b) {
                  a = a[sortKey.key];
                  b = b[sortKey.key];
                  if (sortKey.order == 1) {
                    return a < b;
                  } else {
                    return a > b;
                  }

                  //return (a === b ? 1 : a > b ? 1 : -1);
              });
            }
    
            if (tempStatusKey && tempStatusKey != "") {
                heroes = heroes.filter(heroes => heroes.status == tempStatusKey);
            } else if (typeof tempStatusKey !== 'undefined') {
                heroes = heroes.filter(heroes => heroes.status != tempStatusKey);
            }

            return heroes;
        }
    },
    methods: {
        sortBy: function(key) {
            this.sortKey = key;
        },
        isOption(toCheck) {
            if (this.options.includes(toCheck)){
              return true;
            } else {
              return false;
            }
          },
          loadData() {
            this.tableData = this.cleanDates(this.sentGridData);
            this.copiedData = JSON.parse(JSON.stringify(this.tableData));
          },
          checkSingle: function(e, param) {
            if (this.checkedValues.length){
              this.$emit('showOptions', true);
              this.$emit('checkedValues', this.checkedValues);
            } else {
              this.$emit('showOptions', false);
            }
          },
          async saveEdittedData(){
            let valueIndexes = this.arrayDiff(this.copiedData, this.tableData);
            let valueObject = {};
            let queryParams = {};
      
            if (valueIndexes) {
              for (let i in valueIndexes) {
                if (this.passedCheckboxKey) {
                  valueObject[this.tableData[valueIndexes[i]][this.passedCheckboxKey]] = this.tableData[valueIndexes[i]];
                } else {
                  valueObject[this.tableData[valueIndexes[i]]['_id']['$oid']] = this.tableData[valueIndexes[i]];
                }
              }
            }
            
            //Make a copy of the valueObject so that when delete is used the reload isn't looking for a missing _id in tableData (removed by association otherwise)
            let dataToSave = JSON.parse(JSON.stringify(valueObject));
            let idForEdit = "";
            for (let item in dataToSave) {
              idForEdit = dataToSave[item]['_id']['$oid'];
              delete dataToSave[item]['_id'];
              if (this.orgId) {
                  queryParams['orgId'] = orgParamBase();
              }
      
              try {
                let response = await generalServices.PUT_DATA(this.endPoint, idForEdit, dataToSave[item], queryParams);
              } catch (err) {
                console.log(err);
              }
            }
      
            this.editedValues = {};
            this.loadData();
            
          },
          async deleteData() {
            let itemsToDelete = this.checkedValues;
            let response = "";
            let queryParams = {};
            if (this.orgId) {
                  queryParams['orgId'] = orgParamBase();
              }
      
            for (let i = 0; i < itemsToDelete.length; i++) {
              try {
                response = await generalServices.DELETE_DATA(this.endPoint, itemsToDelete[i], queryParams);
      
                this.showButtonsVal = false;
                this.checkedValues = [];
                this.selectAll = false;
                this.$emit('showOptions', false);
                this.$emit('deleteSuccessful', response.data);
              } catch (err) {
                  console.log(err);
              }
            } 
          },
          getCategories () {
            this.selectData = this.$store.getters.activeCategories;
          },
          showFiles (IdForFiles){
            this.$emit('showFiles', IdForFiles);
          },
          sendOptionInfo (value) {
            this.$emit('showOptions', value);
          },
          openFile (binaryFile) {
            let pdfWindow = window.open("")
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(binaryFile) + "'></iframe>"
            )
          },
          arrayDiff(a, b) {
            let diffIndexes = [];
            for (let i in a) {
              if (JSON.stringify(a[i]) != JSON.stringify(b[i])) {
                diffIndexes.push(i);
              }
            }
            
            return diffIndexes;
          },
          updateCheckedVals (values) {
            this.checkedValues = values;
            this.$emit('checkedValues', this.checkedValues);
          },
          headerSelectAll (value) {
            this.selectAll = value;
          }
    },
    watch: {
        enableDelete() {
            this.deleteData();
        },
        '$store.state.activeCategories': function() {     
            this.selectData = this.$store.getters.activeCategories;   
        },
        sentGridData() {
            this.loadData();
        }
    }
};

