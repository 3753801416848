<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="modal-wrapper"
      @keyup.tab.prevent="trapFocus"
      v-click-outside="clickAwayClose">
        <div class="modal-container eventMessage-container" v-if="transactionFeedback.message != ''">
          <div class="eventMessage-header">
            <div class="modal__close">
              <button type="button" class="modal__close" @click="$emit('close')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <success-error-message
            :httpCode="transactionFeedback.code"
            :httpMessage="transactionFeedback.message"
            @close="clickAwayClose">
          </success-error-message>
        </div>
        <div class="modal-container" v-if="transactionFeedback.message == ''">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="modal__close" @click="clickAwayClose">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <div class="modal-body">
              <div class="modal-body-basic">
                <file-drop
                  :acceptedFormats="'.pdf'"
                  @selectedFiles="setFiles">
                  <data-grid-container slot="existingFileGrid"
                          :columns="gridColumns"
                          :endPoint="fileGrid.url"
                          :sentGridData="fileData"
                          :filterKey="searchQuery"
                          :gridName="'common-grid'"
                          :activeInactive="false"
                          :editableTable="false"
                          :printable="false"
                          :tableName="'files'"
                          @reloadPageInfo="getFilesInfo()">
                  </data-grid-container>
                </file-drop>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-success" type="submit" form="form1" value="Submit" @click.prevent="postModal()">Submit</button>
              <button class="btn btn-outline-default" @click="clickAwayClose">
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { DataGridContainer } from "@/components";
import FileDrop from './FileDrop.vue';
import SuccessErrorMessage from './SuccessErrorMessage.vue';
import modalMixins from '../utilities/modalMixins';
import FileServices from '../services/FileServices';

export default {
  name: 'file-modal',
  props: {
    inputs: Array,
    endPoint: String,
    sentFileId: String
  },
  mixins: [modalMixins],
  components: {
    DataGridContainer,
    FileDrop,
    SuccessErrorMessage
  },
  data: function() {
    return {
      fileId: "",
      show: false,
      selectCategories: [],
      fileData: [],
      formInfo: {},
      searchQuery: "",
      transactionFeedback: {
        'message': "",
        'code': ""
      },
      files:[],
      fileGrid: {
        url: "",
        getUrl: "",
        fileKey: "training"
      },
      gridColumns: [
        {name: "checkbox", type: "checkbox", editable: false},
        {name: "_id", type: "select", editable: false, openFile: true}
      ]
    };
  },
  mounted() {
    this.fileId = this.sentFileId;
    this.fileGrid.url = "training/" + this.fileId + "/";
    this.getFilesInfo();
  },
  filters: {
    kb: function(val) {
      return Math.floor(val/1024);
    }
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    }
  },
  methods: {
    async getFilesInfo (){
      try {
        let fileInfo = await FileServices.GET_FILES("files/" + this.sentFileId + "/", 'training');

        this.fileData = fileInfo.data;
      } catch (err) {
        this.fileData = [];
        console.log(err);
      }
    },
    setFiles (fileArray) {
      this.files = fileArray;
    },
    async postModal (){
      var form_data = new FormData();

      for ( var key in this.formInfo ) {
        form_data.append(key, this.formInfo[key]);
      }
      
      if (this.files) {
        for ( var i in this.files ) {
          form_data.append('file', this.files[i]);
        }
      }

      try {
          let response = await FileServices.POST_FILES(this.endPoint + this.fileId + "/", form_data);

          this.transactionFeedback['message'] = response.data;
          this.transactionFeedback['code'] = response.status;
          //this.getFilesInfo(this.fileId);
      } catch (err) {
          this.transactionFeedback['message'] = err.response.data;
          this.transactionFeedback['code'] = err.response.status;
      }
    }
  }
};
</script>
<style lang="scss">

</style>
