<template>
  <div v-if="tableData" class="dataGrid">
    <table id="dataGrid">
      <grid-header
        :columns="columns">
      </grid-header>
      <grid-body
        :columns="columns"
        :sentGridData="sentGridData"
        :filterKey="filterKey"
        :statusKey="statusKey"
        :ariaInformation="ariaInformation"
        :tableName="tableName"
        :checkBoxKey="checkBoxKey"
        :enableEdit="enableEdit"
        @showOptions="sendOptionInfo"
        @showFiles="showFiles"
        @checkedValues="updateCheckedVals">
      </grid-body>
    </table>
  </div>
</template>
<script>
import generalUtilMixins from '../../utilities/generalUtilMixins';
import gridMixins from '../../utilities/gridMixins';
import generalServices from '../../services/GeneralServices';
import SvgIconContainer from "../Svg/SvgIconContainer.vue";
import CustomCheckbox from "../CustomCheckbox.vue";
import { orgParam, orgParamBase } from "../../utilities/orgParam"
import GridHeader from './GridHeader.vue';
import GridBody from './GridBody.vue';

export default {
  name: 'category-merge-grid',
  components: {
    CustomCheckbox,
    SvgIconContainer,
    GridHeader,
    GridBody
  },
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
    statusKey: String,
    checkBoxKey: String,
    endPoint: String,
    orgId: Boolean,
    enableEdit: Boolean,
    enableDelete: Boolean,
    sentGridData: "",
    ariaInformation: Object,
    tableName: {
      type: String,
      default: "check"
    },
  },
  mixins: [generalUtilMixins, gridMixins],
  data: function() {
    var sortOrders = {};
    this.columns.forEach(function(key) {
      sortOrders[key.name] = 1;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders,
      tableData: [],
      copiedData: [],
      selectData: [],
      receivedData: [],
      checkedValues: [],
      editedValues: {},
      selectAll: false,
      editCells: null,
      passedCheckboxKey: this.checkBoxKey,
      options: ["checkbox", "files"]
    };
  },
  mounted() {  
    this.receivedData = this.$store.getters.activeCategories;
  },
  computed: {
    
  },
  filters: {
 
  },
  methods: {
    async saveEdittedData(){
      let valueIndexes = this.arrayDiff(this.copiedData, this.tableData);
      let valueObject = {};
      let queryParams = {};

      if (valueIndexes) {
        for (let i in valueIndexes) {
          valueObject[i] = {}
          valueObject[i]['chosenNameToKeep'] = this.tableData[valueIndexes[i]]['chosenName'];
          valueObject[i]['chosenNameToMerge'] = this.copiedData[valueIndexes[i]]['chosenName'];
          valueObject[i]['category'] = this.tableData[valueIndexes[i]]['category'];
        }
      }
      
      //Make a copy of the valueObject so that when delete is used the reload isn't looking for a missing _id in tableData (removed by association otherwise)
      let dataToSave = JSON.parse(JSON.stringify(valueObject));
      let idForEdit = "";
      for (let item in dataToSave) {
        if (!this.passedCheckboxKey) {
          idForEdit = dataToSave[item]['_id']['$oid'];
          delete dataToSave[item]['_id'];
        }

        if (this.orgId) {
            queryParams['orgId'] = orgParamBase();
        }

        try {
          let response = await generalServices.POST_DATA(this.endPoint, dataToSave[item], queryParams);
        } catch (err) {
          console.log(err);
        }
      }

      this.editedValues = {};
      this.loadData();
      
    },
  },
  watch: {
    enableEdit(newValue) {
        this.editCells = newValue;
        this.getCategories();
        if (newValue == false) {
            this.saveEdittedData();
        }
    },
  }
};
</script>
<style>
</style>
