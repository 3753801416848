/*
|-------------------------------------------------------------------------------
| Global filters
|-------------------------------------------------------------------------------
*/
import Vue from "vue"

Vue.filter('capitalize', function (str) {
    var initialString = str.charAt(0).toUpperCase() + str.slice(1);
    return initialString.split(/(?=[A-Z])/).join(" ");
});

Vue.filter('filterDateFront', function (numberDate) {
    if (numberDate){
        var date = new Date(numberDate);
        let year = date.getUTCFullYear();
        let month = date.getUTCMonth()+1;
        let day = date.getUTCDate();
        month = ((month <= 9) ? "0" + month : date.getUTCMonth()+1);
        day = ((day <= 9) ? "0" + day : date.getUTCDate());

        return year + '-' + month + '-' + day;
    } else {
        return "";
    }
});
  

