<template>
  <div>
    <section class="legal-hero"
        :class="policyType + '-legal'">
        <div class="flex-row section-container flex-justify-center">
            <div class="hero-svg">
                <svg-icon-container
                    :hidden="false"
                    :icon="policyType == 'general'? 'documentIcon' : 'privacyIcon'"
                    :title="policyType">
                </svg-icon-container>
            </div>
            <div class="hero-txt">
                <h1 class="legal-title">{{ legalTitle }}</h1>
                <p class="revision-info">{{ revisionDate }}</p>
            </div>
        </div>
    </section>
    <section class="legal">
        <div class="flex-row section-container content-container">
            <div class="flex-column flex-1 legal-nav-container">
                <nav class="legal-nav">
                    <ul class="nav flex-column">
                        <li v-for="(item, key) in htmlTemplate">
                            <a :href="'#' + key.trim().toLowerCase()">{{key}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="flex-column flex-column-2">
                <template v-for="(item2, key2) in htmlTemplate">
                    <article>
                        <div :id="key2.trim().toLowerCase()" 
                            class="accordion-waypoint" 
                            @click="accordionTarget(key2.trim().toLowerCase())">
                            <h2>{{key2}}</h2>
                        </div>
                        <div class="accordion-content" v-show="activeAccordion == key2.trim().toLowerCase() || activeAccordion == 'all'" v-html="item2">
                        </div>
                    </article>
                </template>
            </div>
        </div>
    </section>
  </div>
</template>
<script>
import Privacy from '../components/html/Privacy.html'
import TermsOfService from '../components/html/TermsOfService.html'
import SvgIconContainer from '../components/Svg/SvgIconContainer.vue';

export default {
    components: {
        SvgIconContainer
    },
    mixins: [],
    data() {
        return {
            Privacy,
            TermsOfService,
            activeAccordion: "",
            showModal: false,
            windowWidth: "",
            htmlTemplate: null,
            navArray: [],
            templateName: "",
            legalTitle: "",
            revisionDate: "",
            policyType: ""
        };
    },
    created () {
        
    },
    computed: {

    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.getTemplateName();
        //console.log(document.getElementsByTagName("h1"));
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    filters: {
        
    },
    methods: {
        accordionTarget(clickedHeader){
            if (this.windowWidth < 991){
                if (this.activeAccordion == clickedHeader){
                    this.activeAccordion = "";
                } else {
                    this.activeAccordion = clickedHeader;
                }
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth > 991){
                this.activeAccordion = "all";
            } else {
                this.activeAccordion = "";
            }
        },
        cleanHtml(htmlToClean) {
            let tempObject = {};
            let tempKey = "";
            for ( var key in htmlToClean ) {
                if (htmlToClean[key].tagName == "H1") {
                    tempKey = htmlToClean[key].innerHTML;
                    tempObject[tempKey] = [];
                } else if (htmlToClean[key].tagName == "TITLE"){
                    console.log(htmlToClean[key].innerHTML);
                    this.legalTitle = htmlToClean[key].innerHTML;
                } else if (htmlToClean[key].tagName == "DETAILS"){
                    this.revisionDate = htmlToClean[key].innerHTML;
                } else if (htmlToClean[key].tagName == "SUMMARY"){
                    this.policyType = htmlToClean[key].innerHTML;
                } else {
                    if (htmlToClean[key].outerHTML !== undefined) {
                        tempObject[tempKey] = tempObject[tempKey] + htmlToClean[key].outerHTML;
                    }
                }
            }

            return tempObject;
        },
        getTemplateName () {
            if(this.templateName == ""){
                this.templateName = this.$route.name;
            } 

            let wrapper= document.createElement('div');
            wrapper.innerHTML= this[this.templateName];
            this.htmlTemplate = this.cleanHtml(wrapper.children);
        },
    },
    watch:{
        $route (to, from){
            this.templateName = to.name;
            this.getTemplateName();
        }
    } 
};</script>
<style lang="scss">
</style>
