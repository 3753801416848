<template>
  <svg-icon :aria-hidden="hidden" :icon="icon">
    <title v-if="title != ''">{{title}}</title>
  </svg-icon>
</template>
<script>
import SvgIcon from "../Svg/SvgIcon.vue"

export default {
  name: 'svg-icon-container',
  components: {
    SvgIcon
  },
  props: {
    hidden: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: "placeholder"
    },
    title: {
      type: String,
      default: ""
    },
  },
};
</script>
