<template>
  <div class="dataGridContainer">
    <div class="buttonOptions">
      <slot name="additionalButtons"></slot>
      <div class="dataGridButtons" v-if="showButtons">
          <div class="tableOptions">
            <print-dropdown
              :headers="headers"
              :sentGridData="sentGridData"
              :desiredIds="checkedValues"
              :printOptions="printOptions">
            </print-dropdown>
          </div>
      </div>
      <div class="flex-right">
        <input class="form-control" name="query" v-model="filterKey" placeholder="Search" aria-label="Search table"/>
      </div>
    </div>
    <div class="dataGrid">
      <table>
        <thead>
          <tr>
            <td class="check">
              <input type="checkbox" name="tableId" id="selectAllMatrix"
                v-model="selectAll"
                aria-label="Select all rows in table."
                @change="selectAllBoxes">
              <label class="sreader-only" for="selectAllMatrix">Select all table rows</label>
            </td>
            <th scope="col" tabindex="0" v-for="key in headers">
                {{ key | capitalize }}
            </th>
            <th v-if="trainingCourses.length > 0" 
                scope="col" tabindex="0" v-for="course in trainingCourses">
                {{ course }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="Object.keys(copiedData).length < 1">
            <td :colspan="headers.length + trainingCourses.length + 1">
                No information found.
            </td>
          </tr>
          <tr v-else v-for="(entry, index) in filteredData">
            <td>
              <input type="checkbox" name="tableId" :id="'matrix' + index" :value='entry["_id"]["$oid"]'
                v-model="checkedValues"
                :aria-label="'Select information in row ' + index"
                @change="checkSingle($event)">
              <label class="sreader-only" :for="'matrix' + index">Select information in row {{ index }}</label>
            </td>
            <td tabindex="0" v-for="name in headers">
              <div v-if="name == 'hubUUID'" class="lockedCell">
                  <router-link :to='{name: "employeeProfile", params: {id: entry["userId"]["$oid"]}}'>{{entry[name]}}</router-link>
              </div>
              <div v-else :class="name" class="lockedCell">
                  {{entry[name]}}
              </div>
            </td>
            <td tabindex="0" v-for="course in trainingCourses"
                class="matrix"
                :aria-label="course + ' training is ' + entry['training'][course]['class']"
                @click="openTrainingModal(entry['userId']['$oid'], entry['training'][course]['courseInfo'], entry['training'][course]['class'], course)">
                <span :class="((entry['training'][course]['dateIssued'] != '') ? 'hasData' : '')">
                  {{entry['training'][course]['dateIssued']}}
                  <svg-icon-container
                    v-if="entry['training'][course]['class'] == 'warning'"
                    :class="entry['training'][course]['class']"
                    :icon="'exclamationIcon'">
                  </svg-icon-container>
                  <svg-icon-container
                    v-if="entry['training'][course]['class'] == 'expired'"
                    :class="entry['training'][course]['class']"
                    :icon="'xIcon'">
                  </svg-icon-container>
                </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import generalUtilMixins from '../utilities/generalUtilMixins';
import gridMixins from '../utilities/gridMixins';
import PrintDropdown from "./PrintDropdown.vue";
import SvgIconContainer from "./Svg/SvgIconContainer.vue";

export default {
  name: 'matrix-grid',
  components: {
    PrintDropdown,
    SvgIconContainer,
  },
  mixins: [generalUtilMixins, gridMixins],
  props: {
    heroes: Array,
    checkBoxKey: String,
    endPoint: String,
    enableEdit: Boolean,
    sentGridData: "",
    printOptions: Object
  },
  data: function() {
    return {
      sortKey: "",
      sortOrders: {},
      tableData: [],
      copiedData: [],
      checkedValues: [],
      headers: [],
      trainingCourses: [],
      editCells: false,
      selectAll: false,
      editCells: null,
      showButtons: false,
      filterKey: ""
    };
  },
  mounted() {
    this.loadCategories();
  },
  computed: {
    
  },
  filters: {

  },
  methods: {
    editData() {
      if (!this.editCells){
        this.editCells = true;
      } else {
        this.editCells = false;
        this.saveEdittedData();
      }
    },
    loadCategories() {
        this.receivedData = this.$store.getters.activeCategories;
    },
    loadData() {
      this.tableData = this.cleanDates(this.sentGridData);
      this.trainingCourses = [];
      
      //if (this.copiedData.length === 0) {
        //this.tableData = this.sentGridData;
        let categories = ['firstName', 'lastName', 'hubUUID'];
        this.headers = categories;

        var orders = {};
        this.headers.forEach(function(key) {
          orders[key] = 1;
        });
        
        this.sortOrders = orders;

        for (let i in this.tableData) {
          for (let i2 in this.tableData[i]['training']) {
            this.trainingCourses.push(i2);
          }

          break;
        }
      //}

      this.copiedData = JSON.parse(JSON.stringify(this.tableData));
    },
    openTrainingModal (id, value, sentClass, courseName) {
        this.$emit('openTraining', id, value, sentClass, courseName);
    },
    checkSingle () {
      if (this.checkedValues.length){
        this.showButtons = true;
      } else {
        this.showButtons = false;
      }
    },
    selectAllBoxes() {
      this.checkedValues = [];
        if (this.selectAll) {
          for (let i in this.tableData) {
            this.checkedValues.push(this.tableData[i]['_id']['$oid']);
      }
        this.showButtons = true;
      } else {
        this.showButtons = false;
      }
    },
  },
  watch: {
    
  }
};
</script>
<style>
</style>
