/*
|-------------------------------------------------------------------------------
| Mixins for getting files in PrintDropdown component methods.
|-------------------------------------------------------------------------------
*/
import FileServices from '../../services/FileServices';

export default {
    data() {
      return {
          resultPdf: [],
          scale: 2,
      };
    },
    methods: {
      async getFileInfo (ids) {
        let tempFileData = [];
        for (let i in ids) {
          try {
            let fileInfo = await FileServices.GET_FILES("files/" + ids[i] + "/", 'training');
  
            if (fileInfo.data) {
              for (let index in fileInfo.data) {
                tempFileData.push(fileInfo.data[index]);
              }
            }
          } catch (err) {
            //console.log(err);
          }
        }
  
        if (tempFileData.length > 0){
          await this.renderPDF(tempFileData);
        }
      },
      async renderPDF(pdfData) {
        let pdfjs = require("pdfjs-dist/webpack");
        var pdfDecoded = "";
        pdfjs.disableWorker = true;
  
        for (let i in pdfData) {
          pdfDecoded = atob(pdfData[i]['file']['$binary']);
          let loadingTask = pdfjs.getDocument({data: pdfDecoded});
          await loadingTask.promise.then((pdf) => this.renderPages(pdf));
        }
      },          
      async renderPage(page) {
        var viewport = page.getViewport({ scale: this.scale });
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };
        
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var task = page.render(renderContext);
        await task.promise.then(() => {
          this.resultPdf.push({image: canvas.toDataURL('image/jpeg'), width: 600, style: 'image', id: "image"});      
        });
      },      
      async renderPages(pdfDoc) {
        for(var num = 1; num <= pdfDoc.numPages; num++)
          await pdfDoc.getPage(num).then(this.renderPage);
      },
    }
};
