export function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('user'));
  
    if (user && user.access) {
        return { Authorization: 'Token ' + user.access };
    } else {
        return {};
    }
}

export function authToken() {
    let user = JSON.parse(sessionStorage.getItem('user'));
  
    if (user && user.access) {
        return user.access;
    } else {
        return "";
    }
}

export function getRefreshToken() {
    let user = JSON.parse(sessionStorage.getItem('user'));
  
    if (user && user.access) {
        return user.refresh;
    } else {
        return "";
    }
}
  


