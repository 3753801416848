/*
|-------------------------------------------------------------------------------
| Mixins for use with input validation for parent component.
|-------------------------------------------------------------------------------
*/
export default {
  data: function() {
    return {
        validateInputs: false,
    };
  },
  methods: {
    checkInputs() {
        this.validateInputs = true;
    },
    passFailInputs(item) {
        if (item.passed){
            this.postModal(item.updatedValues);
        } else {
            this.validateInputs = item.passed;
        }
    },
  }
};

