<template>
    <div class="printButton"
    v-click-outside="hideMenu">
        <button class='btn btn-outline-default' aria-haspopup="true" aria-label="print selected table information"
              :aria-expanded="showMenu" 
              @click='toggleShow'
              @keyup.down.prevent="moveIntoMenu"
              @keydown.down.prevent
              @keyup.esc.prevent="hideMenu">
            <svg-icon-container 
              focusable="false"
              :icon="'printIcon'">
            </svg-icon-container>
        </button>
        <div v-if='showMenu' class='menu' role="menu" 
          @keyup.down.prevent="moveFocusDown"
          @keydown.down.prevent
          @keyup.up.prevent="moveFocusUp"
          @keydown.up.prevent
          @keyup.esc="hideMenu"
          @keydown.tab.prevent="hideMenu">
            <component v-for="(option, index) in printOptions.options" 
              :key="index"
              :ref="'printButton_' + index"
              :is="option['template']"
              :sentGridData="sentGridData"
              :headers="headers"
              :desiredIds="desiredIds"
              :buttonLabel="option['buttonLabel']"
              :title="printOptions.title"
              :hubUUID="hubUUID">
            </component>
        </div>
    </div>
</template>
<script>
import TableOnly from "./Print/TableOnly.vue";
import ImagesOnly from "./Print/ImagesOnly.vue";
import PublicImagesOnly from "./Print/PublicImagesOnly.vue";
import PublicTablesWithImages from "./Print/PublicTablesWithImages.vue";
import TablesWithImages from "./Print/TablesWithImages.vue";
import TableOnlyMatrix from './Print/TableOnlyMatrix.vue';
import TrainingSummaries from "./Print/TrainingSummaries.vue";
import TrainingSummariesWithImages from "./Print/TrainingSummariesWithImages.vue";
import TableOnlyRequirements from "./Print/TableOnlyRequirements.vue";
import SvgIconContainer from "./Svg/SvgIconContainer.vue";

export default {
  name: 'print-dropdown',
  props: {
    headers: Array,
    sentGridData: "",
    desiredIds: Array,
    printOptions: Object,
    hubUUID: String,
  },
  mixins: [],
  components: {
    'print-tableOnly': TableOnly,
    'print-imagesOnly': ImagesOnly,
    'print-publicImagesOnly': PublicImagesOnly,
    'print-publicTablesWithImages': PublicTablesWithImages,
    'print-tablesWithImages': TablesWithImages,
    'print-tableOnlyMatrix': TableOnlyMatrix,
    'print-trainingSummaries': TrainingSummaries,
    'print-trainingSummariesWithImages': TrainingSummariesWithImages,
    'print-tableOnlyTrainingRequirements': TableOnlyRequirements,
    SvgIconContainer,
  },
  data() {
    return {
      showMenu: false,
      buttonKey: 0,
    };
  },
  mounted() {

  },
  filters: {
    capitalize: function(str) {
      var initialString = str.charAt(0).toUpperCase() + str.slice(1);
      return initialString.split(/(?=[A-Z])/).join(" ")  ;
    },
  },
  methods: {
    toggleShow () {
      this.showMenu = !this.showMenu;
      this.buttonKey = 0;
    },
    moveFocusDown () {
      if (this.showMenu) {
        if (this.buttonKey < (Object.keys(this.$refs).length - 1)) {
          this.buttonKey = this.buttonKey + 1;
        } else {
          this.buttonKey = 0;
        }

        this.$refs["printButton_" + this.buttonKey][0].$el.focus();
      }
    },
    moveFocusUp () {
      if (this.showMenu) {
        if (this.buttonKey >= 1) {
          this.buttonKey = this.buttonKey - 1;
        } else {
          this.buttonKey = Object.keys(this.$refs).length - 1;
        }

        this.$refs["printButton_" + this.buttonKey][0].$el.focus();
      }
    },
    moveIntoMenu () {
      this.$refs["printButton_0"][0].$el.focus();
    },
    hideMenu () {
      this.showMenu = false;
    }
  },
};
</script>