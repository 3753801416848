/*
|-------------------------------------------------------------------------------
| VUEX modules/org.module.js
|-------------------------------------------------------------------------------
| The Vuex data store for the organization id handling.
*/

const orgId = sessionStorage.getItem('orgId');
const initialState = orgId
  ? { requestedOrg: orgId, orgLogo: sessionStorage.getItem('orgLogo'), orgName: sessionStorage.getItem('orgName')}
  : { requestedOrg: "", orgLogo: "", orgName: ""};

export const org = {
    state: initialState,
    getters: {
        activeOrg: state => {
            return state.requestedOrg;
        },
        activeOrgLogo: state => {
            return state.orgLogo;
        },
        activeOrgName: state => {
            return state.orgName;
        }
    },
    actions: {
        updateOrg({commit, state}, orgInfo) {
            console.log(orgInfo);
            commit('setOrg', orgInfo.orgId);
            commit('setLogo', orgInfo.orgLogo);
            commit('setOrgName', orgInfo.orgName);
        },
        removeOrg({commit}) {
            commit('deleteOrg');
        }
    },
    mutations: {
        setOrg(state, id) {
            state.requestedOrg = id;
            sessionStorage.setItem('orgId', id);
        },
        setLogo(state, logo) {
            state.orgLogo = logo;
            sessionStorage.setItem('orgLogo', logo);
        },
        setOrgName(state, orgName) {
            state.orgName = orgName;
            sessionStorage.setItem('orgName', orgName);
        },
        deleteOrg(state){
            state.requestedOrg = "";
            sessionStorage.removeItem('orgId');
        },
        deleteOrgLogo(state){
            state.orgLogo = "";
            sessionStorage.removeItem('orgLogo');
        }
    }
}

