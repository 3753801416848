/*
|-------------------------------------------------------------------------------
| Services for Authentication
|-------------------------------------------------------------------------------
*/
import axiosInstance from "../services/axios/axiosInstance";
import store from '../store/store';

class AuthService {
    async login(user) {
        return axiosInstance
            .post('login/', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.access) {
                    sessionStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    async refreshToken(token) {
        return axiosInstance
            .post('login/refresh/', {
                refresh: token,
            })
            .then(response => {
                if (response.data.access) {
                    let user = JSON.parse(sessionStorage.getItem("user"));
                    user.access = response.data.access;
                    sessionStorage.setItem("user", JSON.stringify(user));
                }

                return response.data;
            });
    }

    logout() {
        sessionStorage.clear();
        store.dispatch('removeOrg');
    }

    register(user) {
        return axiosInstance.post('register/', {
            email: user.email,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            dateOfBirth: user.dateOfBirth
        });
    }

    activate(user) {
        return axiosInstance.post('users/activate/', {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            dateOfBirth: user.dateOfBirth
        });
    }
}

export default new AuthService();


