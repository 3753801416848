/*
|-------------------------------------------------------------------------------
| Services for General Axios Endpoint Queries
|-------------------------------------------------------------------------------
*/
import * as helpers from "../utilities/helperUtils"
import axiosInstance from "../services/axios/axiosInstance";

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

export default {
    GET_DATA (endpoint, params = {}) {
        return axiosInstance.get(helpers.buildUrl(endpoint, helpers.encodeQueryData(params)));
    },
    POST_DATA (endpoint, data, params = {}) {
        return axiosInstance.post(helpers.buildUrl(endpoint, helpers.encodeQueryData(params)), data);
    },
    PUT_DATA (endpoint, id, data, params = {}){
        return axiosInstance.put(helpers.buildUrl(endpoint + id + "/", helpers.encodeQueryData(params)), data);
    },
    DELETE_DATA(endpoint, id, params = {}){
        return axiosInstance.delete(helpers.buildUrl(endpoint + id + "/", helpers.encodeQueryData(params)));
    },
    DELETE_WJSON(endpoint, sentData, params = {}){
        return axiosInstance.delete(helpers.buildUrl(endpoint, helpers.encodeQueryData(params)), { data: sentData });
    }
}

