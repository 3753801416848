/*
|-------------------------------------------------------------------------------
| Mixins for use with grids.
|-------------------------------------------------------------------------------
*/
export default {
    data: function() {
        return {
            queryParams: {},
        }
    },
    mounted() {
        this.queryParams['orgId'] = this.fetchOrgId();
    },
    methods: {
        fetchOrgId () {
            return this.$store.getters.activeOrg;
        }
    }
};

