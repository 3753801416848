<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu>
        <drop-down class="nav-item"
                   title="5 Notifications"
                   title-classes="nav-link"
                   icon="ti-bell" hidden>
          <a class="dropdown-item">Notification 1</a>
          <a class="dropdown-item">Notification 2</a>
          <a class="dropdown-item">Notification 3</a>
          <a class="dropdown-item">Notification 4</a>
          <a class="dropdown-item">Another notification</a>
        </drop-down>
        <li class="nav-item" v-if="!isUserPage">
            <router-link class="nav-link" :to="{name: 'UserProfile'}" @click.native="toggleSidebar">Your Profile</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logout()">
            <i class="ti-settings"></i>
            <p>Logout</p>
          </a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content 
        @click.native="toggleSidebar">
      </dashboard-content>
      <footer>
        <div class="flex-row footer-container">
          <div class="flex-column company-container">
            <div class="logo">
              <svg-icon-container
                class="flex-left logo"
                focusable="false" 
                :icon="'hseAllianceIcon'">
              </svg-icon-container>
            </div>
            <div class="flex-row social-container">
                <a href="https://www.linkedin.com/company/hsealliance/">
                    <svg class="social" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                </a>
              <!--<svg class="social" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>-->
              <!--<svg class="social" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>-->
            </div>
          </div>
          <div class="footer-nav-container flex-row flex-3">
            <!--<div class="flex-column footer-list">
              <h4>Features</h4>
              <ul class="hi-ul">
                <li>Training Management</li>
                <li>Document Management</li>
              </ul>
            </div>-->
            <div class="flex-column footer-list">
              <h4>Company</h4>
              <ul class="hi-ul">
                <!--<li>About Us</li>-->
                <router-link tag="li" :to="{name:'Privacy'}">Privacy Agreement</router-link>
                <router-link tag="li" :to="{name:'TermsOfService'}">Terms of Service</router-link>
              </ul>
            </div>
          </div>
          <div class="copyright">
            <span>Copyright &copy; {{ todayYear }} HSE Alliance Technologies Inc. All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import SvgIconContainer from "../../components/Svg/SvgIconContainer.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SvgIconContainer
  },
  computed: {
    isUserPage(){
        return (this.$route.name === 'Organizations' || this.$route.name === 'UserProfile');
    },
    todayYear() {
      const d = new Date();
      return d.getFullYear();
    }  
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
        this.$store.dispatch("auth/logout")
    }
  }
};
</script>
