/*
|-------------------------------------------------------------------------------
| Mixins for use with input grid validation.
|-------------------------------------------------------------------------------
*/
export default {
  data: function() {
    var requiredInputKeys = {};
    this.inputs.forEach(function(key) {
        if (key.required) {
            requiredInputKeys[key.category] = false;
        }
    });
    return {
        selectData: [],
        formInfo: {
            'status': "Active"
        },
        requiredInputsObj: requiredInputKeys,
        validationMessages: {}
    };
  },
  methods: {
    checkValidity(value) {
        if (value) {
            this.validationMessages = {};
            let requiredInputs = document.querySelectorAll(".modal-body [required]");
            let checkReqInputs = [...requiredInputs].filter((input) => {                
                if (!input.value) {
                    this.validationMessages[input.name] = "This field is required.";
                    return input.value == "";
                }
            });
            
            if (checkReqInputs.length > 0) {
                this.inputs.forEach((key) => {
                    if (key.required) {
                        this.requiredInputsObj[key.category] = false;
                    }
                });

                checkReqInputs.forEach((key) => {
                    this.requiredInputsObj[key.name] = true;
                });
            } else {
                this.postModal();
            }
        }
    },
  }
};

