<template>
  <print-dropdown-button
    :buttonLabel="buttonLabel"
    @printClicked="createTemplate">
  </print-dropdown-button>
</template>
<script>
import printMixins from '../../utilities/print/printMixins';
import printFileMixins from '../../utilities/print/printFileMixins';
import PrintDropdownButton from '../PrintDropdownButton.vue';

export default {
  name: 'print-tableOnlyMatrix',
  components: {
    PrintDropdownButton
  },
  props: {
    buttonLabel: String,
    sentGridData: "",
    desiredIds: Array,
    headers: Array,
    title: String,
  },
  mixins: [printMixins, printFileMixins],
  data() {
    return {
      showMenu: false,
      courses: [],
    };
  },
  mounted() {
    this.courses = this.$store.getters.activeCategories['trainingCourse'];
  },
  methods: {
    async createTemplate () {
      let template = [];
      template.push(this.buildPageHeader(this.title));
      template.push(
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            body: this.buildTable()
          }
        }
      );

      this.docDefinition['pageOrientation'] = "landscape";
      this.docDefinition['content'] = [template];
      this.pdfMake.createPdf(this.docDefinition).download("optionalName.pdf");
    },
    buildTable () {
      let tableStructure = [];
      let headerInfo = [];
      let bodyInfo = [];

      headerInfo.push(this.buildHeaders());
      bodyInfo = this.buildBody();
      tableStructure = [...headerInfo, ...bodyInfo];

      return tableStructure;
    }, 
    buildHeaders () {
      let cleanedHeaders = [];

      for (let i in this.headers) {
        if (!this.isOption(this.headers[i])) {
          cleanedHeaders.push({text: this.$options.filters.capitalize(this.headers[i]), style: 'tableHeader'});
        }
      }
      
      for (let i2 in this.courses) {
        cleanedHeaders.push({text: this.$options.filters.capitalize(this.courses[i2]['name']), style: 'tableHeader'});
      }
      
      return cleanedHeaders;
    },
    buildBody () {
      let cleanedBody = [];
      let tempArray = [];
      let filteredBody = this.filterObject(this.sentGridData);

      for (let i in filteredBody){
        tempArray = [];
        for (let i2 in this.headers) {
          if (!this.isOption(this.headers[i2])) {
            tempArray.push({text: filteredBody[i][this.headers[i2]]});
          }
        }

        for (let i3 in this.courses) {
          tempArray.push({text: filteredBody[i]['training'][this.courses[i3]['name']]['dateIssued'], style: filteredBody[i]['training'][this.courses[i3]['name']]['class']});
        }
        
        cleanedBody.push(tempArray);
      }

      return cleanedBody;
    }
  }
};
</script>