<template>
    <div class="fieldGroupContainer flex-column">
        <template v-for="option in inputs">
            <div class="fieldGroup"
            :class="option.category == 'userComments' ? 'bye-bye' : ''">
                <div class="flex-row flex-align-start">
                    <label :for="option.category" :class="option.required ? 'requiredLabel': ''">
                        <template v-if="!option.alternateLabel">
                            {{ option.category | capitalize }}
                        </template>
                        <template v-else>
                            {{ option.alternateLabel }}
                        </template>
                    </label>
                    <template v-if="option.toolTip">
                        <button class="btn-svg-transparent flex-row" @click.prevent="handleToolTip(option.category)">
                            <svg-icon-container
                                focusable="false" 
                                :icon="'helpIcon'"
                                :title="option.category + ' Tool Tip'">
                            </svg-icon-container>
                        </button>
                    </template>
                </div>
                <template v-if='option.type === "select"'>
                    <template v-if="option.category === 'status'">
                        <select v-model="formInfo['status']" 
                                class="form-control"
                                id="status" 
                                name="status"
                                :class="requiredInputsObj[option.category] ? 'requiredInput': ''"
                                :required="option.required">
                            <option value="Active" selected>
                                Active
                            </option>
                            <option value="Inactive">
                                Inactive
                            </option>
                        </select>
                    </template>
                    <template v-else>
                        <select v-model="formInfo[option.category]" 
                                class="form-control"  
                                :id="option.category" 
                                :name="option.category" 
                                :class="requiredInputsObj[option.category] ? 'requiredInput': ''"
                                :required="option.required">
                            <option value="">
                            </option>
                            <template v-if="hasFrequency(selectData[option.category])">
                                <option v-for="(item, key) in selectData[option.category]" :value="item.name">
                                    {{item.name}}
                                </option>
                            </template>
                            <template v-else>
                                <option v-for="(item, key) in selectData[option.category]" :value="item">
                                    {{item}}
                                </option>
                            </template>
                        </select>
                    </template>
                </template>
                <template v-else-if='option.type === "textarea"'>
                    <template v-if="option.category == 'userComments'">
                        <textarea v-model="formInfo[option.category]" 
                            :id="option.category" 
                            :name="option.category" 
                            class="form-control userComments">
                        </textarea>
                    </template>
                    <template v-else>
                        <textarea v-model="formInfo[option.category]" 
                            :id="option.category" 
                            :name="option.category" 
                            class="form-control" 
                            :class="requiredInputsObj[option.category] ? 'requiredInput': ''"
                            :required="option.required"
                            :aria-describedby="option.toolTip ? option.category + 'ToolTip' : ''">
                        </textarea>
                    </template>
                </template>
                <template v-else>
                    <input v-model="formInfo[option.category]" 
                        :id="option.category" 
                        :name="option.category" 
                        class="form-control" 
                        :class="requiredInputsObj[option.category] ? 'requiredInput': ''"
                        :type="option.type" 
                        :required="option.required"
                        :aria-describedby="option.toolTip ? option.category + 'ToolTip' : ''">
                </template>
                <span 
                    v-if="option.required" 
                    v-show="requiredInputsObj[option.category]"
                    class="requiredText">{{validationMessages[option.category]}}
                </span>
            </div>
            <div v-if="option.toolTip" v-show="toolTips[option.category]" role="tooltip" :id="option.category + 'ToolTip'">
                <div class="flex-row">
                    <button class="btn-svg-transparent flex-right" @click.prevent="handleToolTip(option.category)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                        <title>Close Tool Tip</title>
                            <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            >
                            </path>
                        </svg>
                    </button>
                </div>
                <div class="flex-row flex-align-start flex-no-wrap tooltip-svg">
                    <svg-icon-container
                        focusable="true" 
                        :hidden="false"
                        :icon="'helpIcon'"
                        :title="'Tool Tip'">
                    </svg-icon-container>
                    <p>{{ option.toolTip }}</p>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import SvgIconContainer from "../../components/Svg/SvgIconContainer.vue";

export default {
  name: 'simple-input',
  components: {
    SvgIconContainer
  },
  props: {
    inputs: Array,
    checkValidity: Boolean,
    checkSelector: { 
        type: String,
        default () {
            return ".modal-body"
        }
    },
    sentData: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data: function() {
    var requiredInputKeys = {};
    var toolTipObj = {};
    this.inputs.forEach(function(key) {
        if (key.required) {
            requiredInputKeys[key.category] = false;
        }

        if (key.toolTip) {
            toolTipObj[key.category] = false;
        }
    });
    return {
      selectData: [],
      formInfo: {
          'status': "Active"
      },
      toolTips: toolTipObj,
      requiredInputsObj: requiredInputKeys,
      validationMessages: {}
    };
  },
  mounted() {
    for ( var key in this.inputs ) {
        if (this.sentData) {
            this.$set(this.formInfo, this.inputs[key]['category'], this.sentData[this.inputs[key]['category']]);
        } else {
            if (this.inputs[key]['category'] != "status"){
                this.$set(this.formInfo, this.inputs[key]['category'], "");
            }
        }
    }
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.selectData = this.$store.getters.activeCategories;      
    },
    validateEmail(email) {
        let test = String(email)
            .toLowerCase()
            .match(
                /^\S+@\S+\.\S+$/
            );
        return test;
    },
    changeExpiration(){
        if (this.selectData['trainingCourse']){
            var findCourse = this.selectData['trainingCourse'].find(obj => {
                return obj.name === this.formInfo['trainingCourse']
            })

            if (typeof findCourse !== 'undefined') {
                if (findCourse['frequency'] != "") {
                    var recupDate = new Date(this.formInfo['dateIssued']);
                    var addYears = new Date(recupDate.setFullYear(recupDate.getFullYear() + parseInt(findCourse.frequency)));
                    this.formInfo['dateExpired'] = this.$options.filters.filterDateFront(addYears);
                } else {
                    this.formInfo['dateExpired'] = "";
                }
            }
        }
    },
    isEmptyObject(obj){
        return Object.keys(obj).length === 0;
    },
    hasFrequency(array) {
        try {
            if (array.some(e => 'frequency' in e)) {
                return true;
            } 
        } catch (error) {
            return false;
        }
    },
    handleToolTip(id) {
        this.toolTips[id] = !this.toolTips[id];
    }
  },
  watch: {
    checkValidity(value) {
        if (value) {
            this.validationMessages = {};
            let selector = this.checkSelector + " [required]"
            let requiredInputs = document.querySelectorAll(selector);
            let checkReqInputs = [...requiredInputs].filter((input) => {               
                if (!input.value) {
                    this.validationMessages[input.name] = "This field is required.";
                    return input.value == "";
                }
            });

            //Check email if it isn't blank
            if (!checkReqInputs.some(obj => obj.type == "email")) {
                if (!this.validateEmail(this.formInfo['email'])) {
                    this.validationMessages['email'] = "Email is an invalid format.";
                } else {
                    this.validationMessages['email'] = "";
                }
            }

            if (checkReqInputs.length > 0) {

                this.inputs.forEach((key) => {
                    if (key.required) {
                        this.requiredInputsObj[key.category] = false;
                    }
                });

                checkReqInputs.forEach((key) => {
                    this.requiredInputsObj[key.name] = true;
                });

                if (this.validationMessages['email'] != "") {
                    this.requiredInputsObj['email'] = true;
                }

                this.$emit("valid", {'passed': false});
            } else {
                this.$emit("valid", {'passed': true, 'updatedValues': this.formInfo});
            }
        }
    },
    'formInfo.dateIssued'() {
        this.changeExpiration();
    },
    'formInfo.trainingCourse'() {
        this.changeExpiration();
    },
    sentData() {
        for ( var key in this.inputs ) {
            if (this.inputs[key]['category'] != "status"){
                if (key.includes("date") && this.sentData[this.inputs[key]['category']] == "") {
                    var getValue= document.getElementById("dateIssued");
                    getValue.value = this.sentData[this.inputs[key]['category']];
                } else {
                    this.formInfo[this.inputs[key]['category']] = this.sentData[this.inputs[key]['category']];
                }
            } else {
                this.formInfo['status'] = "Active";
            }
        }
    },
  }
};
</script>
