<template>
  <component :is="tag"
             @click.native="hideSidebar"
             class="nav-item flex-row"
             v-bind="$attrs"
             tag="li"
             tabindex="-1">
    <a class="nav-link flex-row" 
        tabindex="-1"
        role="menuItem" 
        @click="$emit('groupInfo', menuIndex, groupIndex)">
        <svg-icon-container
            focusable="false" 
            :icon="icon">
        </svg-icon-container>
        <p focusable="false">{{name}}</p>
    </a>
  </component>
</template>
<script>
import SvgIconContainer from "../Svg/SvgIconContainer.vue";

export default {
  name: "sidebar-link",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true
    },
    addLink: {
      default: ()=>{}
    },
    removeLink: {
      default: ()=>{}
    }
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link"
    },
    groupIndex: {
      type: Number,
      default: null
    },
    menuIndex: {
      type: Number,
      default: null
    },
  },
  components: {
    SvgIconContainer
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>
</style>
