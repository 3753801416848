<template>
  <div>
    <section class="hubGreen">
        <div class="flex-row section-container">
            <div class="flex-column flex-item-center flex-grow card-wrapper">
                <h2 class="text-align-center">Early access launch is here!</h2>
                <p class="card-summary half-margin">While we continue to build features that meet the needs of the industry, hop into the early access version of HSE HUB to take advantage of our current offerings for a reduced price (where applicable).</p>
            </div>
        </div>
    </section>
    <section>
        <div class="flex-row section-container">
            <div class="flex-column flex-item-center flex-grow card-wrapper">
                <h2 class="text-align-center">Personal profiles that lessen the load</h2>
                <p class="card-summary half-margin">With free access to our personal profiles, employees are able to get rid of holding onto a wallet full of
                    training records. Instead, employees can place their training records in one central location where they can
                    be easily managed, digitally accessed and shared with others.
                </p>
                <a class="btn btn-success flex-justify-center" :href="hubUrl + '/hub/register/'">Register for free!</a>
                <svg-icon-container
                    class="large-section-svg"   
                    :hidden="false"
                    :icon="'hseHubProfileView'">
                </svg-icon-container>
                <div class="flex-row flex-row-gap card-container medium-card-container width-100">
                    <template v-for="item in cards">
                        <card 
                            class="landing-cards"
                            :class="getClasses(item.color, item.bodySvg)">
                            <svg-icon-container
                                slot="headerSVG"
                                class="card-header-svg"    
                                :hidden="false"
                                :icon="item.svg">
                            </svg-icon-container>
                            <div class="flex flex-row" slot="default">
                                <div class="flex-column">
                                    <h3 class="card-title">{{item.title}}</h3>
                                    <ul class="hi-ul odd" slot="default">
                                        <template v-for="listItem in item.list">
                                            <li>
                                                <svg-icon-container
                                                    class="hi-checkmark" 
                                                    :hidden="false"
                                                    :icon="'checkmarkIcon'"
                                                    :title="'Has Training'">
                                                </svg-icon-container>
                                                <span>{{ listItem }}</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <svg-icon-container   
                                    v-if="item.bodySvg"
                                    class="bodySvg"
                                    :hidden="false"
                                    :icon="item.bodySvg">
                                </svg-icon-container>
                            </div>
                        </card>
                    </template>
                </div>
            </div>
        </div>
    </section>
    <section
        v-for="(secDetail, index) in sectionDetails" 
        class="flex-column"
        :class="secDetail.color">
        <div v-if="secDetail.largeTitle" class="flex-row section-container">
            <div class="flex-column section-info">
                <h2>
                    {{ secDetail.largeTitle }}
                </h2>
                <p>{{ secDetail.largeDesc }}</p>
            </div>
        </div>
        <div class="flex-row section-container"
            :class="(isOdd(index))? 'flex-row-reverse' : ''">
            <div class="flex-column section-info">
                <h3 class="section-title">
                    {{ secDetail.descTitle }}
                </h3>
                <ul class="hi-ul odd">
                    <li v-for="lItem in secDetail.descList">
                        <svg-icon-container
                            class="hi-checkmark" 
                            :hidden="false"
                            :icon="'checkmarkIcon'"
                            :title="'Has Training'">
                        </svg-icon-container>
                        <span>{{ lItem }}</span>
                    </li>
                </ul>
            </div>
            <div class="flex-column flex-column-2 svg-container"
                :class="(isOdd(index))? 'flex-item-start' : 'flex-item-end'">
                <svg-icon-container
                    :hidden="false"
                    :icon="secDetail.svg">
                </svg-icon-container>
            </div>
        </div>
    </section>
  </div>
</template>
<script>
import { SvgIconContainer } from "@/components";
import { ContactUsModal } from "@/components";
import modalEventMixins from '../utilities/modalEventMixins';

export default {
  components: {
    'svg-icon-container': SvgIconContainer,
    'contact-modal': ContactUsModal
  },
  mixins: [modalEventMixins],
  data() {
    return {
      activeFeature: "training",
      showModal: false,
      requestDemo: false,
      modalInputs: [
          {type: "text", category: "email"},
      ],
      cards: [
        {
            'svg': 'documentIcon',
            'title': 'Electronically track records',
            'list': [
                "Input training record information and images.",
                "Easily organize and sort between active and inactive records."
            ],
            'color': "blue",
        },
        {
            'svg': 'printIcon',
            'title': 'Print and download with ease',
            'list': [
                "Individually download training records.",
                "Print a summary of your training information, images, or both."
            ],
            'color': "purple",
        },
        {
            'svg': 'connectionIcon',
            'title': 'Connect and share',
            'list': [
                "Realtime sharing of training records through connections with organizations.",
                "Add or remove these connections at any time."
            ],
            'color': "green",
        },
        {
            'svg': 'hubPassIcon',
            'title': 'HubPass - Single card for all records',
            'list': [
                "Display public training records by scanning a code on our card.",
                "Take control of which records are private or public."
            ],
            'color': "blue",
        },
        {
            'svg': 'notificationIcon',
            'title': 'Get notified when needed',
            'list': [
                "Get notified when records are or about to expire.",
                "Change notification preferences whenever needed."
            ],
            'color': "purple",
            'bodySvg': "getNotified",
        },
      ],
      sectionDetails: [
        {
            'largeTitle': "Solutions built for organizations of any size",
            'largeDesc': "Our belief is that, no matter your position in the industry or location in the world, you should have access to digital solutions to meet your occupational health and safety needs.",
            'descTitle': "Set and manage training requirements",
            'descList': [
                "Easily set training requirements for specific categories, such as job title, department, etc.",
                "Create new categories for current and future requirements as needed.",
                "Print training requirements for your organization with a click of a button for activities such as audits."
            ],
            'svg': "trainingRequirements",
            'color': "hubGreen",
        },
        {
            'descTitle': "Add one or more records at a time",
            'descList': [
                "Upload data for multiple training records at a time through our bulk uploading capabilities.",
                "Bulk uploading makes migration from spreadsheets simple.",
                "Able to input information for single training records, including their images, for your employees as they are received."
            ],
            'svg': "addTraining",
            'color': "",
        },
        {
            'descTitle': "Training matrix used for simplicity",
            'descList': [
                "View all active employees training record information in a simple view.",
                "Quickly identify what has or is going to expire through the use of accessible",
                "Print summaries of one or more employee records with a click of a button.",
                "Access individual employee profiles through the use of quick links."
            ],
            'svg': "trainingMatrix",
            'color': "hubPurple",
        },
        {
            'descTitle': "Individual profile view for each employee",
            'descList': [
                "Keep track of basic details for each employee such as job title, project, hire date, etc.",
                "Quickly determine if the employee has met company training requirements through provided summaries.",
                "Log data for completed training while viewing an employees profile.",
            ],
            'svg': "orgProfileView",
            'color': "",
        },
        {
            'descTitle': "Manage one or more organizations with one login",
            'descList': [
                "Scrap the need for multiple accounts.",
                "View and manage all organizations that are owned or have assigned administration permissions under one sign in.",
            ],
            'svg': "manageOrgs",
            'color': "hubBlue",
        }
      ]
    };
  },
  created () {
    
  },
  mounted() {
    
  },
  filters: {
    
  },
  computed: {
    hubUrl() {
        if (process.env.VUE_APP_HUB_URL) {
            return "http://" + process.env.VUE_APP_HUB_URL;
        } else {
            return "";
        }
    }
  },
  methods: {
    getClasses(color, bodySvg){
        var classes = "";
        if (color) {
            classes = color + "-card";
        }

        if (bodySvg) {
            classes = classes + " " + "svg-card";
        }

        return classes;
    },
    isOdd(num) { 
        return num % 2;
    }
  }
};</script>
<style lang="scss">
</style>
