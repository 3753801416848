/*
|-------------------------------------------------------------------------------
| Mixins for use with grids.
|-------------------------------------------------------------------------------
*/
export default {
  data: function() {
    return {
      focusableEls: [],
    }
  },
  computed: {
    fullName: function() {
      return this.employeeData.firstName + " " + this.employeeData.lastName;
    }
  },
  mounted() {
    this.focusableEls = this.$el.childNodes[0].querySelectorAll('button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="date"]:not([disabled]), select:not([disabled])');
    this.focusableEls[0].focus();
  },
  methods: {
    trapFocus(event) {
      var firstFocusableEl = this.focusableEls[0];  
      var lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];

      if ( event.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
        }
      }
    },
    clickAwayClose(){
        this.$emit('close');
    },
    cleanFormData(formInfo, files) {
      var form_data = new FormData();

      for ( var key in formInfo ) {
          if (key.includes("date")) {
              let dateToCheck = "";
              let dateValue = "";
              if (formInfo[key]) {
                dateToCheck = formInfo[key].split("-");
                if (!dateToCheck.includes("NaN") || dateToCheck != "") {
                  dateValue = formInfo[key];
                }
              }
              form_data.append(key, dateValue);
          } else {
              let tempValue = "";
              if (formInfo[key]) {
                tempValue = formInfo[key];
              }
              form_data.append(key, tempValue);
          }
        }
        
        if (this.files) {
            for ( var i in this.files ) {
                form_data.append('file', this.files[i]);
            }
        }

        return form_data;
    },
  }
};

