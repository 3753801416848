<template>
  <button class='menu-item' role="menuitem" tabindex="-1"
          @click='emitClick'>{{buttonLabel}}</button>
</template>
<script>

export default {
  name: 'print-dropdown-button',
  props: {
    buttonLabel: String,
  },
  methods: {
    emitClick () {
      this.$emit("printClicked");
    }
  }
};
</script>