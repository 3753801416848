<template>
  <component
    :is="icon"
    role="img">
    <slot></slot>
  </component>
</template>
<script>
import addTraining from '../../assets/svg/addTraining.svg';
import checkmarkIcon from '../../assets/svg/checkmarkIcon.svg';
import checkmarkOnlyIcon from '../../assets/svg/checkMarkOnlyIcon.svg';
import clientDrivenIcon from '../../assets/svg/clientDrivenIcon.svg';
import connectionIcon from '../../assets/svg/connectionIcon.svg';
import documentIcon from '../../assets/svg/documentIcon.svg';
import downloadIcon from '../../assets/svg/downloadIcon.svg';
import editIcon from '../../assets/svg/editIcon.svg';
import emptyCircleIcon from '../../assets/svg/emptyCircleIcon.svg';
import errorFaceIcon from '../../assets/svg/errorFaceIcon.svg';
import exclamationIcon from '../../assets/svg/exclamationIcon.svg';
import folderIcon from '../../assets/svg/folderIcon.svg';
import fullCircleIcon from '../../assets/svg/fullCircleIcon.svg';
import getNotified from '../../assets/svg/getNotified.svg';
import halfCircleIcon from '../../assets/svg/halfCircleIcon.svg';
import heroImage from '../../assets/svg/heroImage.svg';
import hseAllianceIcon from '../../assets/svg/hseAllianceIcon.svg';
import hubPassIcon from '../../assets/svg/hubPassIcon.svg';
import hseHubProfileView from '../../assets/svg/hseHubProfileView.svg';
import keepItSimpleIcon from '../../assets/svg/keepItSimpleIcon.svg';
import keyIcon from '../../assets/svg/keyIcon.svg';
import manageOrgs from '../../assets/svg/manageOrgs.svg';
import minusIcon from '../../assets/svg/minusIcon.svg';
import notificationIcon from '../../assets/svg/notificationIcon.svg';
import orgProfileView from '../../assets/svg/orgProfileView.svg';
import printIcon from '../../assets/svg/printIcon.svg';
import privacyIcon from '../../assets/svg/privacyIcon.svg';
import profileIcon from '../../assets/svg/profileIcon.svg';
import removeBarrierIcon from '../../assets/svg/removeBarrierIcon.svg';
import saveIcon from '../../assets/svg/saveIcon.svg';
import successFaceIcon from '../../assets/svg/successFaceIcon.svg';
import trainingIcon from '../../assets/svg/trainingIcon.svg';
import trainingManagement from '../../assets/svg/trainingManagement.svg';
import trainingMatrix from '../../assets/svg/trainingMatrix.svg';
import trainingRequirements from '../../assets/svg/trainingRequirements.svg';
import trashCanIcon from '../../assets/svg/trashCanIcon.svg';
import uploadIcon from '../../assets/svg/uploadIcon.svg';
import viewIcon from '../../assets/svg/viewIcon.svg';
import xIcon from '../../assets/svg/xIcon.svg';
import xOnlyIcon from '../../assets/svg/xOnlyIcon.svg';

export default {
  name: 'svg-icon',
  props: {
    icon: {
      type: String,
      required: true,
    }
  },
  components: {
    addTraining,
    checkmarkIcon,
    checkmarkOnlyIcon,
    clientDrivenIcon,
    connectionIcon,
    documentIcon,
    downloadIcon,
    editIcon,
    emptyCircleIcon,
    errorFaceIcon,
    exclamationIcon,
    folderIcon,
    getNotified,
    halfCircleIcon,
    heroImage,
    hseAllianceIcon,
    hseHubProfileView,
    hubPassIcon,
    keepItSimpleIcon,
    keyIcon,
    fullCircleIcon,
    manageOrgs,
    minusIcon,
    notificationIcon,
    orgProfileView,
    printIcon,
    privacyIcon,
    profileIcon,
    removeBarrierIcon,
    saveIcon,
    successFaceIcon,
    trainingIcon,
    trainingManagement,
    trainingMatrix,
    trainingRequirements,
    trashCanIcon,
    uploadIcon,
    viewIcon,
    xIcon,
    xOnlyIcon,
  },
};
</script>
