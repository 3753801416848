<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="modal-wrapper"
      @keyup.tab.prevent="trapFocus"
      v-click-outside="clickAwayClose">
        <div class="modal-container eventMessage-container" v-if="transactionFeedback.message != ''">
          <div class="eventMessage-header">
            <div class="modal__close">
              <button type="button" class="modal__close" @click="clickAwayClose">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <success-error-message
            :httpCode="transactionFeedback.code"
            :httpMessage="transactionFeedback.message"
            @close="clickAwayClose">
          </success-error-message>
        </div>
        <div class="modal-container" v-if="transactionFeedback.message == ''">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="modal__close" @click="clickAwayClose">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="modal-body" v-if="selectData">
            <div class="modal-body-basic">
                <div class="fieldGroup">
                    <label for="category">Category</label>
                    <select v-model="formInfo['category']" 
                            class="form-control" 
                            :class="requiredInputsObj['category'] ? 'requiredInput': ''"
                            id="category" 
                            name="category" 
                            required="">
                        <option v-for="(item, key) in categories" :value="item">
                            {{item | capitalize}}
                        </option>
                    </select>
                    <span
                        v-show="requiredInputsObj['category']"
                        class="requiredText">{{validationMessages['category']}}
                    </span>
                </div>
                <div class="fieldGroup">
                    <label for="value">Enter the name of the {{formInfo['category'] | capitalize}}</label>
                    <input v-model="formInfo['value']" 
                        class="form-control" 
                        :class="requiredInputsObj['value'] ? 'requiredInput': ''"    
                        id="value" 
                        name="value" 
                        type="text" 
                        required="">
                    <span
                        v-show="requiredInputsObj['value']"
                        class="requiredText">{{validationMessages['value']}}
                    </span>
                </div>
                <template v-if="formInfo['category'] == 'trainingCourse'">
                    <div class="fieldGroup">
                        <label for="retrainFrequency">Retraining Frequency (in Years)</label>
                        <input v-model="formInfo['retrainFrequency']" id="retrainFrequency" name="retrainFrequency" class="form-control" type="text" required="">
                    </div>
                    <div class="fieldGroup">
                        <label for="applyTo">Apply expiration to:</label>
                    </div>
                    <label class="radio-inline" for="newRecords">
                        <input v-model="formInfo['task']" type="radio" id="newRecords" name="applyTo[1]" value="">Training moving forwards only
                    </label>
                    <label class="radio-inline" for="allRecords">
                        <input v-model="formInfo['task']" type="radio" id="allRecords" name="applyTo[1]" value="updateAll">Previous and future training
                    </label>
                </template>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-success" type="submit" form="form1" value="Submit" @click.prevent="checkValidity(true)">Submit</button>
              <button class="btn btn-outline-default" @click="clickAwayClose">
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import generalServices from '../services/GeneralServices';
import modalMixins from '../utilities/modalMixins';
import inputValidationMixin from '../utilities/inputValidationMixin';
import { orgParamBase } from '../utilities/orgParam';
import SuccessErrorMessage from './SuccessErrorMessage.vue';
let counter = 0;

export default {
  name: 'category-modal',
  props: {
    inputs: Array
  },
  mixins: [modalMixins, inputValidationMixin],
  components: {
    SuccessErrorMessage
  },
  data: function() {
    return {
      show: false,
      selectCategories: [],
      formInfo: {
          'category': "",
          'value': "",
          'retrainFrequency': "",
          'task': ""
      },
      transactionFeedback: {
        'message': "",
        'code': ""
      },
      categories: [
          "department",
          "jobTitle",
          "project",
          "provinceState",
          "trainingCourse",
      ]
    };
  },
  mounted() {

  },
  filters: {

  },
  methods: {
    async postModal (){
      try {
        let response = await generalServices.POST_DATA("categories/", this.formInfo, {'orgId': orgParamBase()});

        this.transactionFeedback['message'] = response.data;
        this.transactionFeedback['code'] = response.status;
        this.$emit('updateGrid', counter++);
      } catch (err) {
        this.transactionFeedback['message'] = err.response.data;
        this.transactionFeedback['code'] = err.response.status;
      }
    }
  }
};
</script>
<style lang="scss">

</style>
