<template>
  <thead>
    <tr>
      <template v-for="key in columns">
        <th scope="col" tab-index="0" aria-controls="dataGrid" tabindex="0"
            v-if="key.name != 'checkbox'"
            @click="communicateSort(key.name)"
            :id="key.name"
            :style="">
          {{ key.name | capitalize }}
          <i v-if="sortOrders[key.name] != 0" v-html="sortOrders[key.name]"></i>
        </th>
        <td v-else-if="key.name == 'checkbox'" class="check">
          <input type="checkbox" name="selectAll" :id="'selectAll'+tableName" 
            aria-label="select all rows in table"
            @change="selectedAll">
          <label class="sreader-only" :for="'selectAll'+tableName">Select all table rows</label>
        </td>
      </template>
    </tr>
  </thead>
</template>
<script>
import generalUtilMixins from '../../utilities/generalUtilMixins';
import gridMixins from '../../utilities/gridMixins';
import SvgIconContainer from "../Svg/SvgIconContainer.vue";
import CustomCheckbox from "../CustomCheckbox.vue";

export default {
  name: 'grid-header',
  components: {
    CustomCheckbox,
    SvgIconContainer
  },
  props: {
    columns: Array,
    tableName: {
      type: String,
      default: "check"
    },
  },
  mixins: [generalUtilMixins, gridMixins],
  data: function() {
    var sortOrders = {};
    this.columns.forEach(function(key) {
      sortOrders[key.name] = false;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders,
      selectAll: false
    };
  },
  mounted() {  
    
  },
  computed: {
    
  },
  filters: {
 
  },
  methods: {
    selectedAll() {
      this.selectAll = !this.selectAll;
      this.$emit('selectedAll', this.selectAll);
    },
    communicateSort(key) {
        Object.keys(this.sortOrders).forEach((index) => {
            if (index != key) {
                this.sortOrders[index] = 0;
            } else {
                if (this.sortOrders[index] == 0) {
                    this.sortOrders[index] = '&#9650';
                } else {
                    if (this.sortOrders[index] == '&#9650') {
                        this.sortOrders[index] = '&#9660';
                    } else {
                        this.sortOrders[index] = '&#9650';
                    }
                }
            }
        });
        this.$emit('communicateSort', key);
    }
  },
  watch: {
  
  }
};
</script>
<style>
</style>
