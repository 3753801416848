<template>
  <div v-if="tableData" class="dataGridContainer">
    <div class="buttonOptions">
        <div class="typicalButtons">
        <template class="dataGridButtons" v-if="showButtonsVal">
            <button type="button" class="btn btn-outline-default" aria-label="'Delete selected links to organizations.'"
                    @click="deleteData">
                <svg-icon-container
                    focusable="false" 
                    :icon="'trashCanIcon'">
                </svg-icon-container>      
            </button>
        </template>
        </div>
        <div class="flex-right">
            <input class="form-control" name="query" v-model="filterKey" placeholder="Search" aria-label="Search table"/>
        </div>
    </div>
    <div class="dataGrid">
      <table>
        <thead>
          <tr>
            <td class="check">
              <input type="checkbox" name="tableId" id="selectAllMatrix"
                v-model="selectAll"
                aria-label="Select all rows in table."
                @change="selectAllBoxes">
              <label class="sreader-only" for="selectAllMatrix">Select all table rows</label>
            </td>
            <th scope="col" tabindex="0" v-for="key in headers">
                {{ key | capitalize }}
            </th>
          </tr>
        </thead>
        <tbody>
            <tr v-if="filteredData.length < 1">
                <td :colspan="headers.length + 1">
                    No information found.
                </td>
            </tr>
            <tr v-else v-for="(entry, index) in filteredData">
                <td>
                <input type="checkbox" name="tableId" :id="'link' + index" :value='entry["requestingOrg"]["$oid"]'
                    v-model="checkedValues"
                    :aria-label="'Select information in row ' + index"
                    @change="checkSingle($event)">
                <label class="sreader-only" :for="'link' + index">Select information in row {{ index }}</label>
                </td>
                <td tabindex="0">
                    {{entry['legalName']}}
                </td>
                <td>
                    <template v-if="!entry['accepted']">
                        <button type="button" class="btn btn-outline-default" 
                            :aria-label="'Accept'" 
                            @click="acceptLink(index)">
                            Approve Connection
                        </button>
                    </template>
                    <template v-if="entry['accepted']">
                        Linked
                    </template>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import generalUtilMixins from '../utilities/generalUtilMixins';
import gridMixins from '../utilities/gridMixins';
import SvgIconContainer from "./Svg/SvgIconContainer.vue";
import generalServices from '../services/GeneralServices';

export default {
  name: 'link-grid',
  components: {
    SvgIconContainer,
  },
  mixins: [generalUtilMixins, gridMixins],
  props: {
    heroes: Array,
    checkBoxKey: String,
    endPoint: String,
    enableEdit: Boolean,
    sentGridData: "",
  },
  data: function() {
    return {
      sortKey: "",
      sortOrders: {},
      tableData: [],
      copiedData: [],
      checkedValues: [],
      headers: ['legalName', 'status'],
      selectAll: false,
      filterKey: "",
      showButtonsVal: false,
    };
  },
  mounted() {
    this.receivedData = this.$store.getters.activeCategories;
  },
  methods: {
    loadData() {
      this.tableData = this.cleanDates(this.sentGridData);
      this.copiedData = JSON.parse(JSON.stringify(this.tableData));
    },
    checkSingle: function() {
      if (this.checkedValues.length){
        this.showButtonsVal = true;
      } else {
        this.showButtonsVal = false;
      }
    },
    selectAllBoxes() {
      this.checkedValues = [];
        if (this.selectAll) {
          for (let i in this.tableData) {
            this.checkedValues.push(this.tableData[i]['requestingOrg']['$oid']);
      }
        this.showButtonsVal = true;
      } else {
        this.showButtonsVal = false;
      }
    },
    async acceptLink (index) {
        try {
          let response = await generalServices.PUT_DATA("/activate/links/" + this.tableData[index]['requestingOrg']['$oid'], "");

          this.tableData[index]['accepted'] = true;
        } catch (err) {
            console.log(err);
        }
    },
    async deleteData() {
      let itemsToDelete = this.checkedValues;
      let response = "";
      console.log(this.checkedValues);

      for (let i = 0; i < itemsToDelete.length; i++) {
        console.log("processing");
        try {
          response = await generalServices.DELETE_DATA("links/", itemsToDelete[i]);

          this.showButtonsVal = false;
          this.checkedValues = [];
          this.selectAll = false;
          this.$emit('deleteSuccessful', response.data);
        } catch (err) {
            console.log(err);
        }
      } 
    },
  },
  watch: {
    sentGridData() {
      this.loadData();
    }
  }
};
</script>
<style>
</style>
