<template>
  <transition name="fade">
    <div class="modal-mask">
      <div class="modal-wrapper"
      @keyup.tab.prevent="trapFocus"
      v-click-outside="clickAwayClose">
        <div class="modal-container eventMessage-container" v-if="transactionFeedback.message != ''">
          <div class="eventMessage-header">
            <div class="modal__close">
              <button type="button" class="modal__close" @click="clickAwayClose">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <success-error-message
            :httpCode="transactionFeedback.code"
            :httpMessage="transactionFeedback.message"
            @close="clickAwayClose">
          </success-error-message>
        </div>
        <div class="modal-container" v-if="transactionFeedback.message == ''">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="modal__close" @click="$emit('close')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-body-basic">
              <simple-input
                  :inputs="inputs"
                  :checkValidity="validateInputs"
                  @valid="passFailInputs">
              </simple-input>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-success" type="submit" form="form1" value="Submit" @click.prevent="checkInputs()">Submit</button>
              <button class="btn btn-outline-default" @click="clickAwayClose">
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import generalServices from '../services/GeneralServices';
import modalMixins from '../utilities/modalMixins';
import { orgParamBase } from '../utilities/orgParam';
import SimpleInput from './Modals/SimpleInput.vue';
import SuccessErrorMessage from './SuccessErrorMessage.vue';
import inputValidationParent from '../utilities/inputValidationParent';

export default {
  name: 'contact-modal',
  props: {
    inputs: Array,
    demo: {
      type: Boolean,
      default: false
    }
  },
  mixins: [modalMixins, inputValidationParent],
  components: {
    SuccessErrorMessage,
    SimpleInput
  },
  data: function() {
    return {
      show: false,
      selectCategories: [],
      selectData: [],
      formInfo: {
        'subject': ""
      },
      transactionFeedback: {
        'message': "",
        'code': ""
      },
    };
  },
  mounted() {
    if (this.demo) {
        this.formInfo['subject'] = "Demo";
        this.formInfo['message'] = "I would like to request a demo!";
    }
  },
  filters: {

  },
  methods: {
    async postModal (formInfo){
        if ('userComments' in formInfo) {
            if (formInfo['userComments'] != undefined) {
                this.transactionFeedback['message'] = "Thank you for contacting us! We will get back to you.";
                this.transactionFeedback['code'] = 200;
                return;
            } else {
                delete formInfo['userComments'];
            }
        }
        
        try {
            let response = await generalServices.POST_DATA("contact/", formInfo, "");

            this.transactionFeedback['message'] = response.data;
            this.transactionFeedback['code'] = response.status;
        } catch (err) {
            this.transactionFeedback['message'] = err.response.data;
            this.transactionFeedback['code'] = err.response.status;
        }
    }
  }
};
</script>
<style lang="scss">

</style>
