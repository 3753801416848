/*
|-------------------------------------------------------------------------------
| Services for General Axios Endpoint Queries
|-------------------------------------------------------------------------------
*/
import * as helpers from "../utilities/helperUtils";
import axiosInstance from "../services/axios/axiosInstance";

axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';

export default {
    GET_FILES (endpoint, collection, additionalParams) {
        let paramObject = {}
        for (let params in additionalParams) {
            paramObject[params] = additionalParams[params];
        }
        paramObject["collection"] = collection;
        return axiosInstance.get(helpers.buildUrl(endpoint, helpers.encodeQueryData(paramObject)));
    },
    POST_FILES (endpoint, data, params = {}) {
        return axiosInstance.post(helpers.buildUrl(endpoint, helpers.encodeQueryData(params)), data);
    }
}

