<template>
  <div class="dataGridContainer">
    <div class="buttonOptions">
      <div class="typicalButtons">
        <slot name="additionalButtons"></slot>
        <template class="dataGridButtons" v-if="showButtonsVal">
            <print-dropdown
              v-if="printable"
              :headers="columns"
              :sentGridData="sentGridData"
              :desiredIds="checkedValues"
              :printOptions="printOptions"
              :hubUUID="hubUUID">
            </print-dropdown>
        </template>
      </div>
      <div class="activeInactiveSearch flex-right">
        <input v-if="searchable" id="tableSearch" class="form-control" name="query" v-model="searchQuery" placeholder="Search" aria-label="Search table"/>
      </div>
    </div>
    <component :is="gridName"
              :columns="columns"
              :endPoint="endPoint"
              :sentGridData="sentGridData"
              :filterKey="searchQuery"
              :statusKey="statusKey"
              :enableEdit="saveData"
              :enableDelete="!startDelete"
              :ariaInformation="ariaInformation"
              :tableName="tableName"
              :orgId="orgId"
              :checkBoxKey="checkBoxKey"
              @showOptions="showButtons"
              @deleteSuccessful="successfulDelete"
              @showFiles="showFiles"
              @checkedValues="updateCheckedVals">
    </component>
  </div>
</template>
<script>
import { DataGrid } from "@/components";
import CategoryGrid from "./Grids/CategoryGrid.vue"
import CategoryMergeGrid from "./Grids/CategoryMergeGrid.vue"
import CommonGrid from "./Grids/CommonGrid.vue";
import SvgIconContainer from "./Svg/SvgIconContainer.vue";
import PrintDropdown from "./PrintDropdown.vue";
import LinkGrid from './LinkGrid.vue';

export default {
  name: 'data-grid-container',
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
    gridName: String,
    checkBoxKey: "",
    endPoint: String,
    orgId: {
      type: Boolean,
      default: false
    },
    sentGridData: "",
    searchable: {
      type: Boolean,
      default: true
    },
    printable: {
      type: Boolean,
      default: true
    },
    tableName: String,
    activeInactive: {
      type: Boolean,
      default: true
    },
    editableTable: {
      type: Boolean,
      default: true
    },
    printOptions: Object,
    ariaInformation: Object,
    hubUUID: String,
  },
  components: {
    "category-grid": CategoryGrid,
    "category-merge-grid": CategoryMergeGrid,
    "common-grid": CommonGrid,
    "link-grid": LinkGrid,
    DataGrid,
    PrintDropdown,
    SvgIconContainer
  },
  mounted() {

  },
  data: function() {
    var statusKey = "";
    if (this.activeInactive) {
      statusKey = "Active";
    } else {
      statusKey = "";
    }
    return {
      searchQuery: "",
      statusKey: statusKey,
      showButtonsVal: false,
      saveData: false,
      startDelete: false,
      checkedValues: [],
    };
  },
  filters: {

  },
  methods: {
    deleteData() {
      if(confirm("Do you really want to delete?")){
        this.startDelete = true;
      }
    },
    successfulDelete (value){
      this.startDelete = false;
      this.$emit('reloadPageInfo', value);
    },
    editData() {
      if (!this.saveData){
        this.saveData = true;
      } else {
        this.saveData = false;
      }
    },
    showButtons (param) {
      this.showButtonsVal = param;
    },
    showFiles (idToSend){
      this.$emit('showFiles', idToSend);
    },
    updateCheckedVals (values) {
      this.checkedValues = values;
    }
  },
  watch: {
    
  }
};
</script>
<style>
</style>
