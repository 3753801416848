/*
|-------------------------------------------------------------------------------
| VUEX modules - Auth
|-------------------------------------------------------------------------------
| The Vuex data store for the Auth Services
*/
import AuthService from '../../services/AuthServices';
import router from '../../router/index';

const user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
        return AuthService.login(user).then(
            user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    },
    register({ commit }, user) {
        return AuthService.register(user).then(
            response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            error => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
    activate({ commit }, user) {
        return AuthService.activate(user).then(
            response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            error => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
    refreshToken({ commit }, token) {
        return AuthService.refreshToken(token).then(
            user => {
                commit('refreshSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('logout');
                return Promise.reject(error);
            }
        );
    }
  },
  mutations: {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.user = user;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
        router.push('/login')
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    refreshSuccess(state) {
        state.user = user;
    }
  }
};


