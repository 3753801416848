<template>
  <div v-if="tableData" class="dataGrid">
    <table id="dataGrid">
      <thead>
        <tr>
          <th scope="col" tab-index="0" aria-controls="dataGrid" tabindex="0"
              v-if="key.name != 'checkbox'" v-for="key in columns"
              @click="sortBy(key.name)"
              :id="key.name"
              :class="{ active: sortKey == key.name }"
              :style="">
            {{ key.name | capitalize }}
            <span class="arrow" :class="sortOrders[key.name] > 0 ? 'asc' : 'dsc'">
            </span>
          </th>
          <td v-else class="check">
            <input type="checkbox" name="selectAll" :id="'selectAll'+tableName" 
              v-model="selectAll"
              aria-label="select all rows in table"
              @change="selectAllBoxes">
            <label class="sreader-only" :for="'selectAll'+tableName">Select all table rows</label>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="filteredData.length < 1">
          <td :colspan="columns.length">
            No information found.
          </td>
        </tr>
        <tr v-else v-for="(entry, index) in filteredData">
          <td tabindex="0" v-if="!isOption(key.name)" v-for="key in columns">
            <div v-if="!key.editable" class="lockedCell">
              <div v-if="key.urlName">
                <router-link :to='{name: key.urlName, params: {id: entry["userId"]["$oid"]}}'>{{entry[key.name]}}</router-link>
              </div>
              <div v-else-if="key.openFile">
                <a class="" @click="openFile(entry['file']['$binary'])">{{entry['name']}}</a>
              </div>
              <div v-else>
                {{entry[key.name]}}
              </div>
            </div>
            <div v-else class="editableCell">
              <div v-show="!editCells">
                <div v-if='key.type === "date"'>
                  {{entry[key.name] | filterDateFront}}
                </div>
                <div v-else>
                  {{entry[key.name]}}
                </div>
              </div>
              <input v-if='key.type === "text"' v-show="editCells" class="editingCell form-control" type="text"
                    :aria-labelledby="key.name"
                    v-model="entry[key.name]">
              <select v-if='key.type === "select"' v-show="editCells" class="form-control" required="" 
                        :id="key.category" 
                        :name="key.category" 
                        :aria-labelledby="key.name"
                        v-model="entry[key.name]">
                    <template v-if="key.name === 'status'">
                            <option value="Active" :selected="entry[key.name] == 'Active'">
                                Active
                            </option>
                            <option value="Inactive" :selected="entry[key.name] == 'Inactive'">
                                Inactive
                            </option>
                    </template>
                    <template v-if="key.name === 'trainingCourse'">
                        <option v-if="selectData[key.name]" v-for="item in selectData[key.name]" :selected="item == entry[key.name]">
                            {{item.name}}
                        </option>
                    </template>
                    <template v-else>
                        <option v-if="selectData[key.name]" v-for="item in selectData[key.name]" :selected="item == entry[key.name]">
                            {{item}}
                        </option>
                    </template>
              </select>
              <input v-if='key.type === "date"' 
                    v-show="editCells" type="date"
                    class="form-control"
                    :aria-labelledby="key.name"
                    v-model="entry[key.name]">
            </div>
          </td>
          <td v-else>
            <div v-if="key.name == 'checkbox'">
                <input type="checkbox" name="tableId" :id="tableName + index" :value='passedCheckboxKey ? entry[passedCheckboxKey] : entry["_id"]["$oid"]'
                    v-model="checkedValues"
                    :aria-label="'Select information in row ' + index"
                    @change="checkSingle($event)">
                <label class="sreader-only" :for="tableName + index">Select information in row {{ index }}</label>
            </div>
            <button v-if="key.name == 'files'" type="button" class="btn btn-outline-default" 
                    :aria-label="'Open files for ' + entry[ariaInformation.specificRecordKey] + ' ' + ariaInformation.tableCollection" 
                    @click="showFiles(entry['_id']['$oid'])">
              <svg-icon-container
                focusable="false" 
                :icon="'folderIcon'">
              </svg-icon-container>
            </button>       
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import generalUtilMixins from '../utilities/generalUtilMixins';
import gridMixins from '../utilities/gridMixins';
import generalServices from '../services/GeneralServices';
import SvgIconContainer from "./Svg/SvgIconContainer.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import { orgParam, orgParamBase } from "../utilities/orgParam"

export default {
  name: 'data-grid',
  components: {
    CustomCheckbox,
    SvgIconContainer
  },
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
    statusKey: String,
    checkBoxKey: String,
    endPoint: String,
    orgId: Boolean,
    enableEdit: Boolean,
    enableDelete: Boolean,
    sentGridData: "",
    ariaInformation: Object,
    tableName: {
      type: String,
      default: "check"
    },
  },
  mixins: [generalUtilMixins, gridMixins],
  data: function() {
    var sortOrders = {};
    this.columns.forEach(function(key) {
      sortOrders[key.name] = 1;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders,
      tableData: [],
      copiedData: [],
      selectData: [],
      receivedData: [],
      checkedValues: [],
      editedValues: {},
      selectAll: false,
      editCells: null,
      passedCheckboxKey: this.checkBoxKey,
      options: ["checkbox", "files"]
    };
  },
  mounted() {  
    this.receivedData = this.$store.getters.activeCategories;
  },
  computed: {
    
  },
  filters: {
 
  },
  methods: {
    isOption(toCheck) {
      if (this.options.includes(toCheck)){
        return true;
      } else {
        return false;
      }
    },
    loadData() {
      this.tableData = this.cleanDates(this.sentGridData);
      this.copiedData = JSON.parse(JSON.stringify(this.tableData));
    },
    checkSingle: function(e, param) {
      if (this.checkedValues.length){
        this.$emit('showOptions', true);
        this.$emit('checkedValues', this.checkedValues);
      } else {
        this.$emit('showOptions', false);
      }
    },
    selectAllBoxes() {
        this.checkedValues = [];
        if (this.selectAll) {
            for (let i in this.tableData) {
                if (this.passedCheckboxKey) {
                  this.checkedValues.push(this.tableData[i][this.passedCheckboxKey]);
                } else {
                  this.checkedValues.push(this.tableData[i]['_id']['$oid']);
                }
        }
        this.$emit('showOptions', true);
        this.$emit('checkedValues', this.checkedValues);
			} else {
        this.$emit('showOptions', false);
      }
    },
    async saveEdittedData(){
      let valueIndexes = this.arrayDiff(this.copiedData, this.tableData);
      let valueObject = {};
      let queryParams = {};

      if (valueIndexes) {
        for (let i in valueIndexes) {
          if (this.passedCheckboxKey) {
            valueObject[this.tableData[valueIndexes[i]][this.passedCheckboxKey]] = this.tableData[valueIndexes[i]];
          } else {
            valueObject[this.tableData[valueIndexes[i]]['_id']['$oid']] = this.tableData[valueIndexes[i]];
          }
        }
      }
      
      //Make a copy of the valueObject so that when delete is used the reload isn't looking for a missing _id in tableData (removed by association otherwise)
      let dataToSave = JSON.parse(JSON.stringify(valueObject));
      let idForEdit = "";
      for (let item in dataToSave) {
        idForEdit = dataToSave[item]['_id']['$oid'];
        delete dataToSave[item]['_id'];
        if (this.orgId) {
            queryParams['orgId'] = orgParamBase();
        }

        try {
          let response = await generalServices.PUT_DATA(this.endPoint, idForEdit, dataToSave[item], queryParams);
        } catch (err) {
          console.log(err);
        }
      }

      this.editedValues = {};
      this.loadData();
      
    },
    async deleteData() {
      let itemsToDelete = this.checkedValues;
      let response = "";
      let queryParams = {};
      if (this.orgId) {
            queryParams['orgId'] = orgParamBase();
        }

      for (let i = 0; i < itemsToDelete.length; i++) {
        //let orgId = ((orgParam() != "") ? "")orgParam();
        try {
          response = await generalServices.DELETE_DATA(this.endPoint, itemsToDelete[i], queryParams);

          this.showButtonsVal = false;
          this.checkedValues = [];
          this.selectAll = false;
          this.$emit('showOptions', false);
          this.$emit('deleteSuccessful', response.data);
        } catch (err) {
            console.log(err);
        }
      } 
    },
    getCategories () {
      this.selectData = this.$store.getters.activeCategories;
    },
    showFiles (IdForFiles){
      this.$emit('showFiles', IdForFiles);
    },
    openFile (binaryFile) {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(binaryFile) + "'></iframe>"
      )
    },
    arrayDiff(a, b) {
      let diffIndexes = [];
      for (let i in a) {
        if (JSON.stringify(a[i]) != JSON.stringify(b[i])) {
          diffIndexes.push(i);
        }
      }
      
      return diffIndexes;
    }
  },
  watch: {
    enableEdit(newValue) {
      this.editCells = newValue;
      this.getCategories();
      if (newValue == false) {
        this.saveEdittedData();
      }
    },
    enableDelete() {
      this.deleteData();
    },
    '$store.state.activeCategories': function() {     
      this.selectData = this.$store.getters.activeCategories;   
    },
    sentGridData() {
      this.loadData();
    }
  }
};
</script>
<style>
</style>
